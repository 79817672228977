import {useCallback} from 'react';

/**
 * Stub implementation reason:
 *
 * If you're providing an initial state object,
 * make sure that you don't pass it on web and that there's no deep link to handle.
 *
 * Source: https://reactnavigation.org/docs/navigation-container/#initialstate
 */
export function useInitialNavigationState() {
  return {
    fetch: useCallback(async () => {}, []),
    fetched: true,
    state: undefined,
  };
}
