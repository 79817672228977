import {
  actionChannel,
  all,
  put,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';
import {loginJwtSelectors} from '@store/selectors';
import {logout, setLoginParsedJwt} from '@store/actions';
import {api} from '@b2cmessenger/doppio-core';
import {Logger} from '@b2cmessenger/doppio-shared';
import {StampGiveJwtParsedPayload} from '@utils/JWT';

function* removeLoginJwt() {
  const parsedJwt = yield select(loginJwtSelectors.parsedJwt);
  Logger.verboseTag('removeLoginJwt', 'parsedJwt: ', parsedJwt);

  if (parsedJwt !== null) {
    yield put(setLoginParsedJwt(null));
  }
}

function* dropLoginJwtOnLogout() {
  const channel = yield actionChannel(logout);
  while (true) {
    yield take(channel);
    yield* removeLoginJwt();
  }
}

function* registerStampOnServer(payload: StampGiveJwtParsedPayload) {
  if (payload !== null) {
    const {placeId, issuedBy, jwt} = payload;
    const form = new FormData();
    form.append('jwt', jwt);

    try {
      yield api.default.post<{}>(
        `/api/v2/place/${String(placeId)}/stamps/employee/${issuedBy}/register`,
        form,
      );
      return true;
    } catch (e) {
      Logger.errorTag('registerStampOnServer', e);
      return false;
    }
  }
}

function* runJwtAction(action: ReturnType<typeof setLoginParsedJwt>) {
  if (action.payload !== null) {
    try {
      Logger.verboseTag('runJwtAction', 'payload', action.payload);

      switch (action.payload.type) {
        case 'sg': {
          yield registerStampOnServer(action.payload);
          return true;
        }
        default: {
          return false;
        }
      }
    } catch (e) {
      Logger.errorTag('runJwtAction', e);
    }
  }
}

export default function* loginJwtSaga() {
  yield takeLatest(setLoginParsedJwt, runJwtAction);
  yield all([dropLoginJwtOnLogout()]);
}
