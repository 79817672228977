import {useCallback, useReducer, useState} from 'react';
import {useStampOperation} from '@screens/Dashboard/hooks/useStampOperation';
import {slice} from '@screens/Dashboard/slice';
import {batch, useDispatch, useSelector, useStore} from 'react-redux';
import {
  applyStampOperationResult,
  performInit,
  updateUser,
} from '@store/actions';
import {
  loginedUserSelectors,
  stampAwardsSelectors,
  userPlacesSelectors,
} from '@store/selectors';
import {
  handleDynamicLink,
  isHandledDynamicLinkStampResult,
  resolveLink,
} from '@utils/DynamicLinks/handleDynamicLink';
import {
  isAwardOperationResult,
  StampOperationResult,
  UserOwn,
} from '@typings/ApiSpec';
import {apiMethods, Analytics, api} from '@b2cmessenger/doppio-core';
import {Logger} from '@b2cmessenger/doppio-shared';

import {actions as userPlacesActions} from '@store/slices/userPlaces';
import {actions as stampsActions} from '@store/slices/stamps';
import * as JWT from '@utils/JWT';
import NetworkError from '@utils/Errors/NetworkError';
import {checkAndUpdatePlaceAppearance} from '@utils/placeAppearance';
import {localization, useTranslation} from '@shared';
import type {UserStampBalances} from '@b2cmessenger/doppio-core/src/types/ApiSpec';
import {AxiosRequestConfig} from 'axios';
import {Alert} from '@components/common/Alert';

interface HookOptions {
  onScanError: (title: string, message: string) => void;
  setStampedPlaceId: (placeId: number) => void;
}

export function useDashboardStampState({
  onScanError,
  setStampedPlaceId,
}: HookOptions) {
  const isLoggedIn = useSelector(loginedUserSelectors.isLoggedIn);
  const {t} = useTranslation();

  const reduxDispatch = useDispatch();
  const store = useStore();
  const getPlaceName = useCallback(
    (placeId: number) => {
      return userPlacesSelectors.place(store.getState())(placeId)?.name;
    },
    [store],
  );
  const getAwardName = useCallback(
    (awardId: number, placeId: number) => {
      return stampAwardsSelectors.placeAwardName(store.getState())(
        placeId,
        awardId,
      );
    },
    [store],
  );
  const onStampBalanceChange = useCallback(
    (result: StampOperationResult) => {
      reduxDispatch(applyStampOperationResult(result));
    },
    [reduxDispatch],
  );

  const {loading, run} = useStampOperation();

  const [state, dispatch] = useReducer(
    slice.reducer,
    slice.reducer(undefined, {type: '@@DUMMY'}),
  );

  const [scanned, setScanned] = useState(false);
  const [balanceLoading, setBalanceLoading] = useState(false);

  const displayScanner = useCallback(() => {
    setScanned(false);
    dispatch(slice.actions.scanner());
  }, []);

  const displayScanButton = useCallback(
    () => dispatch(slice.actions.idle()),
    [],
  );
  const clearOsBusinessDemo = useCallback(() => {
    dispatch(slice.actions.clearOsBusinessDemo());
  }, []);

  const executeStampOperation = useCallback(
    async (parsedJwt: ReturnType<typeof JWT.parse>) => {
      const result = await run(parsedJwt);
      if (!result) {
        dispatch(slice.actions.idle());
        onScanError(
          t('Screens.Dashboard.ScanState.notDoppioQr'),
          t('Screens.Dashboard.ScanState.askBusinessForTheQr'),
        );
        return;
      }

      if ('error' in result) {
        if (typeof result.error !== 'object') {
          dispatch(
            slice.actions.stampError(
              result.quantity,
              getStampError(result.code, result.error as string),
              getPlaceName(result.placeId),
              result.placeId,
            ),
          );
          return;
        }

        if (typeof result.error === 'object') {
          if ('awardId' in result && result.awardId !== undefined) {
            dispatch(
              slice.actions.awardError(
                result.error.remains,
                result.awardQuantity,
                getAwardName(result.awardId, result.placeId),
                getPlaceName(result.placeId),
                result.placeId,
              ),
            );
          }
        }
        return;
      }

      onStampBalanceChange(result);
      if (isAwardOperationResult(result)) {
        dispatch(
          slice.actions.award(
            result.award_quantity,
            result.award.award_name,
            result.place.name,
            result.place.id,
          ),
        );
        Analytics.logEvent('redeem_award', {
          quantity: result.award_quantity,
        });
      } else {
        dispatch(
          slice.actions.stamp(
            result.current,
            result.place.name,
            result.place.id,
            parsedJwt?.os,
          ),
        );
        Analytics.logEvent('receive_stamp', {
          quantity: result.current,
        });
      }
    },
    [getAwardName, getPlaceName, onScanError, onStampBalanceChange, run, t],
  );

  const updateBalance = useCallback(
    async (placeId: number) => {
      try {
        setBalanceLoading(true);
        let userStampBalances: UserStampBalances = [];
        let placeIds: number[] = [];

        if (isLoggedIn) {
          userStampBalances = await apiMethods.getUseStampBalances();
          placeIds = userStampBalances.map((r) => r.place_id);
        }

        if (!placeIds.includes(placeId)) {
          placeIds.push(placeId);
          userStampBalances.push({
            place_id: placeId,
            balance: 0,
            updated_at: getCurrentUpdatedAtString(),
          });
        }

        const placeSearchResponse = await apiMethods.placeSearch({
          ids: placeIds,
        });
        reduxDispatch(userPlacesActions.upsertMany(placeSearchResponse.places));
        reduxDispatch(stampsActions.updateBalances({userStampBalances}));
      } catch (e) {
      } finally {
        setBalanceLoading(false);
      }
    },
    [isLoggedIn, reduxDispatch],
  );

  const executeStampOperationAsGuest = useCallback(
    async (parsedJwt: ReturnType<typeof JWT.parse>) => {
      try {
        const user = await createVirtualUser();
        if (!user) {
          return;
        }

        const {access_token: _accessToken, ...loginedUser} = user;
        api.setClientCredentials(_accessToken);

        await executeStampOperation(parsedJwt);

        batch(() => {
          reduxDispatch(updateUser({accessToken: _accessToken, loginedUser}));
          reduxDispatch(performInit());
        });
      } catch (e) {
        Alert.alert(
          t('AppBanner.ErrorOccurred'),
          t('AppBanner.tryLater') || undefined,
        );
      }
    },
    [executeStampOperation, reduxDispatch, t],
  );

  const executeJwtOperation = useCallback(
    async (jwt: string | null) => {
      if (!jwt) {
        dispatch(slice.actions.idle());
        onScanError(
          t('Screens.Dashboard.ScanState.notDoppioQr'),
          t('Screens.Dashboard.ScanState.askBusinessForTheQr'),
        );
        return;
      }

      try {
        const parsed = JWT.parse(jwt);
        if ('placeId' in parsed && parsed.placeId) {
          await checkAndUpdatePlaceAppearance(parsed.placeId);
        }

        if (parsed.type === 'sb') {
          await updateBalance(parsed.placeId);
          dispatch(slice.actions.idle());
          return parsed;
        } else {
          if (!isLoggedIn) {
            await executeStampOperationAsGuest(parsed);
            return;
          }

          await executeStampOperation(parsed);
        }
      } catch (e) {}
    },
    [
      onScanError,
      t,
      updateBalance,
      isLoggedIn,
      executeStampOperation,
      executeStampOperationAsGuest,
    ],
  );

  const onCodeScanned = useCallback(
    async ({data}: {data: string}) => {
      try {
        setScanned(true);
        const dl = await resolveLink(data);
        Logger.verboseTag('useDashboardStampState', '[onCodeScanned] dl: ', dl);
        const result = await handleDynamicLink(dl);

        if (result && 'placeId' in result) {
          await checkAndUpdatePlaceAppearance(result.placeId);
          dispatch(slice.actions.loading(result.placeId));
        }
        if (result && isHandledDynamicLinkStampResult(result)) {
          const jwtData = await executeJwtOperation(result.jwt);
          if (jwtData && jwtData.type === 'sb') {
            setStampedPlaceId?.call(null, jwtData.placeId);
          }
        }
      } catch (e) {
        dispatch(slice.actions.idle());
        if (e instanceof NetworkError) {
          onScanError(
            t('Screens.Dashboard.ScanState.internetRequired'),
            t('Screens.Dashboard.ScanState.connectToInternet'),
          );
        } else {
          onScanError(
            t('Screens.Dashboard.ScanState.notDoppioQr'),
            t('Screens.Dashboard.ScanState.askBusinessForTheQr'),
          );
        }
      }
    },
    [executeJwtOperation, onScanError, setStampedPlaceId, t],
  );

  return {
    state,
    loading: loading || balanceLoading,
    displayScanButton,
    displayScanner,
    scanned,
    onCodeScanned,
    executeJwtOperation,
    clearOsBusinessDemo,
  };
}

// Дата example: 2018-11-12 13:40:50
function getCurrentUpdatedAtString() {
  const d = new Date();
  const date = d.toISOString().split('T')[0];
  const time = d.toTimeString().split(' ')[0];
  return `${date} ${time}`;
}

function getStampError(
  code: number | undefined,
  fallbackMessage: string,
): string {
  if (code === 1201001) {
    return localization.t('Screens.Dashboard.codeAlreadyUsed');
  }

  return fallbackMessage;
}

async function createVirtualUser(
  requestConfig?: AxiosRequestConfig,
): Promise<UserOwn | undefined> {
  return api.default
    .post<UserOwn>('/api/v2/user/virtual/create', {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
      },
    })
    .then(api.getResponseData)
    .catch((e) => {
      Logger.errorTag('createVirtualUser', e);
      throw api.parseErrorToHumanReadableMessage(e);
    });
}
