import {
  Button,
  Navbar,
  Pusher,
  Screen,
  Spacer,
  ThemeProvider,
  useTheme,
  useTypography,
} from '@b2cmessenger/doppio-components';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import {navigationRef} from '@navigation/navigationRef';
import {useScreenPrimaryButtonStyle} from '@components/hooks/useScreenPrimaryButtonStyle';
import {Platform, Text} from 'react-native';
import {createSlice} from '@reduxjs/toolkit';
import {useDispatch, useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {Alert} from '@components/common/Alert';
import {logout} from '@store/actions';
import CheckboxItem from '@components/common/CheckboxItem';
import {api, LoginedUser} from '@b2cmessenger/doppio-core';
import {Logger} from '@b2cmessenger/doppio-shared';
import {useTranslation} from '@shared';

const initialState = {consent: 1};
const slice = createSlice({
  name: 'removalConsents',
  initialState: initialState,
  reducers: {
    reset: () => ({...initialState}),
    toggleCustomersConsent(state) {
      state.consent =
        state.consent === initialState.consent
          ? state.consent - 1
          : initialState.consent;
    },
  },
});

export function AccountRemoval() {
  const {t} = useTranslation();

  const pbsStyle = useScreenPrimaryButtonStyle();
  const {body, mediumBody} = useTypography();
  const {colors} = useTheme();

  const loginedUserProfile = useSelector(
    loginedUserSelectors.profile,
  ) as Exclude<ReturnType<typeof loginedUserSelectors.profile>, null>;
  const [state, dispatch] = useReducer(slice.reducer, {...initialState});

  useEffect(() => {
    Logger.verboseTag('state: ', state);
  }, [state]);

  const goBack = useCallback(() => {
    dispatch(slice.actions.reset());
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard');
    }
  }, []);
  const {loading, remove} = useAccountRemove();
  const dispatchAction = useDispatch();

  const toggleCustomerConsent = useCallback(() => {
    dispatch(slice.actions.toggleCustomersConsent());
  }, []);
  const removeAccountButtonHandler = useCallback(async () => {
    Alert.alert(
      t('Screens.AccountRemoval.areYouSure'),
      t('Screens.AccountRemoval.areYouSureDescription') || undefined,
      [
        {
          style: 'destructive',
          text: t('Screens.AccountRemoval.removeAccount') || undefined,
          onPress: async () => {
            const success = await remove(loginedUserProfile.id);
            if (success) {
              dispatchAction(logout());
              setTimeout(
                Alert.alert,
                333,
                t('Screens.AccountRemoval.accountHasBeenDeleted'),
              );
            } else {
              Alert.alert(
                t('Screens.AccountRemoval.accountDeleteError'),
                t('Screens.AccountRemoval.accountDeleteErrorDescription') ||
                  undefined,
              );
            }
          },
        },
        {
          style: 'cancel',
          text: t('Screens.AccountRemoval.cancel') || undefined,
        },
      ],
    );
  }, [dispatchAction, loginedUserProfile.id, remove, t]);

  return (
    <Screen
      navbar={useMemo(
        () => (
          <Navbar />
        ),
        [],
      )}>
      <Screen.Heading>
        {t('Screens.AccountRemoval.accountRemoval')}
      </Screen.Heading>
      <Spacer />

      <>
        <Text style={body}>
          {t('Screens.AccountRemoval.generalWarning.youCanDelete')}{' '}
          <Text style={mediumBody}>
            {t('Screens.AccountRemoval.generalWarning.doppio')}
          </Text>{' '}
          {t('Screens.AccountRemoval.generalWarning.accountAnyTime')}
        </Text>
        <Spacer />
        <>
          <CheckboxItem
            disabled={loading}
            status={state.consent === 0 ? 'checked' : 'unchecked'}
            onPress={toggleCustomerConsent}>
            <Text style={body}>
              {t('Screens.AccountRemoval.iUnderstandAllMyWillBeLost')}
            </Text>
          </CheckboxItem>
        </>
        <Spacer />
        {state.consent === 0 ? (
          <>
            <Text style={body}>
              {t('Screens.AccountRemoval.confirmHint', {
                context: Platform.OS,
              })}
            </Text>
            <Spacer />
            <Spacer />
            <Pusher />
            <ThemeProvider colors={{brand: colors.red}}>
              <Button
                title={t('Screens.AccountRemoval.removeAccount')}
                onPress={loading ? undefined : removeAccountButtonHandler}
                loading={loading}
              />
            </ThemeProvider>
            <Spacer />
          </>
        ) : (
          <Pusher />
        )}
      </>

      <Button.Secondary
        disabled={loading}
        onPress={goBack}
        title={
          state.consent === 0
            ? t('Screens.AccountRemoval.cancel')
            : t('Screens.AccountRemoval.close')
        }
        style={pbsStyle}
      />
    </Screen>
  );
}

export function useAccountRemove() {
  const [loading, setLoading] = useState(false);
  const remove = useCallback(async (id: number) => {
    try {
      setLoading(true);
      await removeAccount(id);
      setLoading(false);
      return true;
    } catch (_e) {
      setLoading(false);
      Logger.errorTag('useAccountRemove', _e);
      return false;
    }
  }, []);
  return {loading, remove};
}

async function removeAccount(id: LoginedUser['id']) {
  return api.default
    .post('/api/v2/user/destroy', {
      id,
    })
    .then(api.getResponseData);
}
