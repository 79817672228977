import RNConfig from 'react-native-config';

interface TypedConfig {
  LOG_SEVERITY: 'debug' | 'info' | 'warn' | 'error';

  APP_ID: string;
  API_URL: string;

  GOOGLE_WEB_CLIENT_ID: string;
  GOOGLE_IOS_CLIENT_ID: string;
  FACEBOOK_APP_ID: string;

  SENTRY_DSN: string;

  FIREBASE_ANALYTICS_ENV_LABEL?: string;

  FIREBASE_WEB_API_KEY: string;
  FIREBASE_WEB_PROJECT_ID: string;
  FIREBASE_WEB_APP_ID: string;
  FIREBASE_WEB_MEASUREMENT_ID: string;

  DYNAMIC_LINK_DOMAIN_BUSINESS: string;
  DOMAIN_URI_PREFIX_BUSINESS: string;
  ANDROID_PACKAGE_NAME_BUSINESS: string;
  IOS_APP_STORE_ID_BUSINESS: string;
  IOS_BUNDLE_ID_BUSINESS: string;

  DYNAMIC_LINK_DOMAIN_CLIENT: string;
  DOMAIN_URI_PREFIX_CLIENT: string;
  ANDROID_PACKAGE_NAME_CLIENT: string;
  IOS_APP_STORE_ID_CLIENT: string;
  IOS_BUNDLE_ID_CLIENT: string;
}

const {
  LOG_SEVERITY = 'debug',
  FIREBASE_ANALYTICS_ENV_LABEL,
  ...restConfig
} = RNConfig;

const Config = {
  LOG_SEVERITY,
  FIREBASE_ANALYTICS_ENV_LABEL: FIREBASE_ANALYTICS_ENV_LABEL || undefined,
  ...restConfig,
} as TypedConfig;

export default Config;
