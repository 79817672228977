import {icons, Screen} from '@b2cmessenger/doppio-components';
import {Platform, View} from 'react-native';
import React, {ComponentProps, useCallback, useMemo, useState} from 'react';
import {useLogoutPrompt} from '@components/hooks/useLogoutPrompt';
import {IconButtonList} from '@components/common/IconButton';
import {navigationRef} from '@navigation/navigationRef';
import {useTranslation} from '@shared';
import {useOnPressDownloadApp} from '@screens/Dashboard/Dashboard';
import {IconAppClient} from '@components/common/icons/SvgIcon';
const {IconOptions, LogOutIcon} = icons;

export function NavbarOptions({data}: ComponentProps<typeof IconButtonList>) {
  return (
    <View
      style={{
        paddingHorizontal: Screen.PADDING_HORIZONTAL,
        paddingVertical: Screen.PADDING_HORIZONTAL * 2,
      }}>
      <IconButtonList data={data} />
    </View>
  );
}

function LogOutIconFillNone(props: any) {
  return <LogOutIcon {...props} fill="none" />;
}

function DownloadIconFillNone(props: any) {
  return (
    <IconAppClient
      {...props}
      fill={'none'}
      width={props.size}
      height={props.size}
    />
  );
}

export function useNavbarOptions() {
  const {t} = useTranslation();

  const [navbarOptionsVisible, setNavbarOptionsVisible] = useState(false);
  const onPressLoggedInOptions = useCallback(() => {
    setNavbarOptionsVisible(true);
  }, []);

  const promptLogout = useLogoutPrompt();
  const onPressAccountSettings = useCallback(() => {
    setNavbarOptionsVisible(false);
    navigationRef.current?.navigate('Settings');
  }, []);
  const onPressLogout = useCallback(() => {
    setNavbarOptionsVisible(false);
    promptLogout?.call(null);
  }, [promptLogout]);

  const onPressDownloadApp = useOnPressDownloadApp();

  const navbarOptionsListData = useMemo(() => {
    const downloadApp =
      Platform.OS === 'web'
        ? [
            {
              label: t('Screens.Dashboard.DownloadAppBanner.app'),
              IconComponent: DownloadIconFillNone,
              accessibilityValue: {
                text: t('Screens.Dashboard.DownloadAppBanner.app'),
              },
              caption: undefined,
              onPress: onPressDownloadApp,
            },
          ]
        : [];

    return [
      ...downloadApp,
      {
        label: t('common.accountSettings'),
        IconComponent: IconOptions,
        accessibilityValue: {text: t('common.accountSettings')},
        caption: undefined,
        onPress: onPressAccountSettings,
      },
      {
        label: t('common.logout'),
        IconComponent: LogOutIconFillNone,
        accessibilityValue: {text: t('common.logout')},
        caption: undefined,
        onPress: onPressLogout,
      },
    ];
  }, [onPressAccountSettings, onPressDownloadApp, onPressLogout, t]);

  return {
    navbarOptionsListData,
    navbarOptionsVisible,
    setNavbarOptionsVisible,
    onPressLoggedInOptions,
    promptLogout,
  };
}
