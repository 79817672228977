import {useDispatch, useSelector} from 'react-redux';
import {initSelectors, appTrackingSelectors} from '@store/selectors';
import React, {useCallback, useLayoutEffect, useMemo, useRef} from 'react';
import {Platform, StatusBar} from 'react-native';
import RNBootSplash from 'react-native-bootsplash';
import {performInit} from '@store/actions';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {NavigationContainer} from '@react-navigation/native';
import {navigationRef, setNavigationReady} from '@navigation/navigationRef';
import {NavigationTheme} from '@navigation/Theme';
import {Provider as PaperProvider} from 'react-native-paper';
import theme, {settings as themeSettings} from './Theme';
import {RootNavigator} from '@screens/RootNavigator';
import {linking} from '@navigation/linking';
import {useInitialNavigationState} from '@components/hooks/useInitialNavigationState';
import {RootNavigatorScreenParams} from '@navigation/config';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';

import {
  Analytics,
  FirebaseRemoteConfigProvider,
} from '@b2cmessenger/doppio-core';
import FirebaseRemoteConfigContext, {
  FRCDefaults,
} from '@utils/FirebaseRemoteConfigContext';

export function AppContent() {
  const {
    fetch: fetchInitialNavigationState,
    fetched: initialNavigationStateFetched,
    state: initialNavigationState,
  } = useInitialNavigationState();

  const dispatch = useDispatch();
  const initCompleted = useSelector(initSelectors.initCompleted);
  const appTrackingPermissionAsked = useSelector(
    appTrackingSelectors.permissionAsked,
  );
  const appReady = useMemo(
    () => initCompleted && initialNavigationStateFetched,
    [initialNavigationStateFetched, initCompleted],
  );

  useLayoutEffect(() => {
    if (!initCompleted) {
      dispatch(performInit());
    }
  }, [dispatch, initCompleted]);
  useLayoutEffect(() => {
    if (!initialNavigationState && appTrackingPermissionAsked) {
      fetchInitialNavigationState();
    }
  }, [
    initialNavigationState,
    appTrackingPermissionAsked,
    fetchInitialNavigationState,
  ]);
  useLayoutEffect(() => {
    if (appReady) {
      if (Platform.OS === 'web') {
        const loaderWrapper = window.document.getElementById('loader-wrapper');
        if (loaderWrapper) {
          loaderWrapper.remove();
        }
      } else {
        RNBootSplash.hide({fade: true});
      }
    }
  }, [appReady]);

  const routeNameRef = useRef<keyof RootNavigatorScreenParams>();

  const updateStatusBar = useCallback(() => {
    const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;
    if (Platform.OS === 'ios' && currentRouteName) {
      StatusBar.setBarStyle(
        currentRouteName === 'SignIn' ? 'light-content' : 'dark-content',
      );
    }
  }, []);

  const onStateChange = useCallback(async () => {
    const previousRouteName = routeNameRef.current;
    const currentRouteName = navigationRef.current?.getCurrentRoute()?.name;
    if (previousRouteName !== currentRouteName && currentRouteName) {
      Analytics.logScreenView(currentRouteName);
    }

    updateStatusBar();
  }, [updateStatusBar]);

  const onReady = useCallback(() => {
    setNavigationReady();
    updateStatusBar();
  }, [updateStatusBar]);

  return !appReady ? null : (
    <SafeAreaProvider>
      <NavigationContainer
        initialState={initialNavigationState}
        linking={linking}
        onReady={onReady}
        onStateChange={onStateChange}
        ref={navigationRef}
        theme={NavigationTheme}>
        <PlaceAppearance.Provider>
          <FirebaseRemoteConfigProvider
            Context={FirebaseRemoteConfigContext}
            defaults={FRCDefaults}
            initializationDone={initCompleted}>
            <PaperProvider theme={theme} settings={themeSettings}>
              <RootNavigator />
            </PaperProvider>
          </FirebaseRemoteConfigProvider>
        </PlaceAppearance.Provider>
      </NavigationContainer>
    </SafeAreaProvider>
  );
}
