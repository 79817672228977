import React, {ComponentProps} from 'react';
import {colors} from '@b2cmessenger/doppio-shared';
import {Card, icons, Spacer, Typography} from '@b2cmessenger/doppio-components';
import {StyleProp, StyleSheet, Text, TextStyle, View} from 'react-native';
import {useTranslation} from '@shared';
const {IconCry} = icons;

export const NetworkErrorContentHeight = 120;
function NetworkError({style}: {style?: ComponentProps<typeof Card>['style']}) {
  const {t} = useTranslation();

  return (
    <Card
      backgroundColor={colors.black}
      paddingVertical={0}
      shadowColor={colors.black}
      style={[style]}>
      <View style={styles.content}>
        <IconCry size={44} />
        <Spacer height={8} />
        <Text style={styles.title}>
          {t('Screens.Dashboard.NetworkError.noInternetConnection')}
        </Text>
        <Spacer height={2} />
        <Text style={Typography.smallBody as StyleProp<TextStyle>}>
          {t('Screens.Dashboard.NetworkError.dataMayBeOutdated')}
        </Text>
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  content: {
    alignItems: 'center',
    justifyContent: 'center',
    height: NetworkErrorContentHeight,
  },
  title: {
    ...StyleSheet.flatten(Typography.button),
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.white,
  },
});

export default NetworkError;
