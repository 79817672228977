import React, {useEffect} from 'react';
import {
  RootNavigatorScreenParams,
  RootNavigatorScreenProps,
} from '@navigation/config';
import {Screen} from '@b2cmessenger/doppio-components';
import {colors} from '@b2cmessenger/doppio-shared';
import {ActivityIndicator, StyleSheet} from 'react-native';
import * as JWT from '@utils/JWT';
import useAuthorizeByAccessToken from '@components/hooks/auth/useAuthorizeByAccessToken';
import {useIsRegistered} from '@screens/RootNavigator';

export function LinkScreen({
  route,
  navigation,
}: RootNavigatorScreenProps<'Link'>) {
  const isRegistered = useIsRegistered();
  const {authorizeByAccessToken} = useAuthorizeByAccessToken();

  useEffect(() => {
    async function handleRouteParams() {
      let screenName: keyof RootNavigatorScreenParams =
        getDefaultScreen(isRegistered);
      let params: {jwt: string} | undefined;

      if ('accessToken' in route.params && route.params.accessToken) {
        const loginedUser = await authorizeByAccessToken(
          route.params.accessToken,
        );
        const _isRegistered = !loginedUser?.is_generated;
        screenName = getDefaultScreen(_isRegistered);
      }

      if ('jwt' in route.params) {
        params = getRouteParamsByJwt(route.params.jwt);
      }

      return {screenName, params};
    }

    handleRouteParams().then(({screenName, params}) => {
      navigation.replace(screenName, params);
    });
  }, [route.params, isRegistered, navigation, authorizeByAccessToken]);

  return (
    <Screen>
      <ActivityIndicator
        size="large"
        color={colors.brand}
        style={StyleSheet.absoluteFill}
      />
    </Screen>
  );
}

function isValidJWTType(type: string | null) {
  if (!type) {
    return false;
  }
  return ['sa', 'sg', 'sb'].indexOf(type) !== -1;
}

function getRouteParamsByJwt(jwt: string) {
  const parsedJWT = JWT.parse(jwt);
  const params = isValidJWTType(parsedJWT.type) ? {jwt} : undefined;
  return params;
}
function getDefaultScreen(isRegistered: boolean | null | unknown) {
  return isRegistered ? 'Dashboard' : 'DashboardGuest';
}
