import {slices} from '@b2cmessenger/doppio-core';
import {actions as loginJwt} from './loginJwt.slice';
import {actions as appTrackingActions} from './slices/appTracking';

const {actions: shared} = slices.shared;
const {actions: app} = slices.app;

const {
  performInit,
  init,
  reInit,
  performRegister,
  register,
  login,
  updateUser,
  logout,
  applyStampOperationResult,
  changeLanguage,
} = shared;

export {
  performInit,
  init,
  reInit,
  performRegister,
  register,
  login,
  updateUser,
  logout,
  applyStampOperationResult,
  changeLanguage,
};

const {
  performLogin,
  performLoginViaOtp,
  performLoginViaApple,
  performLoginViaFacebook,
  performLoginViaGoogle,
  loginFailed,
  dismissLoginError,
} = app;

export {
  performLogin,
  performLoginViaOtp,
  performLoginViaApple,
  performLoginViaFacebook,
  performLoginViaGoogle,
  loginFailed,
  dismissLoginError,
};

const {matchers} = slices.shared;
const {isInitLikeAction, isUserInfoAwareAction} = matchers;

export {isInitLikeAction, isUserInfoAwareAction};

const {set: setLoginParsedJwt} = loginJwt;

export {setLoginParsedJwt};

const {setStatus: setAppTrackingStatus} = appTrackingActions;
export {setAppTrackingStatus};
