import {configureStore, getDefaultMiddleware} from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import {sagas} from '@b2cmessenger/doppio-core';
import rootSaga from './sagas';
import {persistReducer, persistStore} from 'redux-persist';
import storageDriver from './storageDriver';
import {reducer} from './reducer';
import {expireReducer} from './expireReducer';

const {runSaga} = sagas;

const persistedReducer = persistReducer(
  {
    key: 'root',
    version: 1,
    storage: storageDriver,
    blacklist: ['init', 'app', 'appTracking', 'network'],
    transforms: [
      expireReducer('loginJwt', {
        expireSeconds: 60 * 30,
        expiredState: {},
      }),
    ],
  },
  reducer,
);

const sagaMiddleware = createSagaMiddleware();
const middleware = [
  sagaMiddleware,
  ...getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
    immutableCheck: false,
  }),
];

export const store = configureStore({
  reducer: persistedReducer,
  middleware,
});

export const persistor = persistStore(store);

runSaga(sagaMiddleware, rootSaga);
