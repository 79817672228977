import React from 'react';
import {DefaultTheme} from 'react-native-paper';
import {Icon} from '@components/common/icons';

const theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    primary: 'green',
  },
};

const settings = {
  // @ts-ignore
  icon: (props) => <Icon {...props} />,
};
export default theme;
export {settings};
