import {
  Navbar,
  Screen,
  icons,
  Typography,
  Spacer,
} from '@b2cmessenger/doppio-components';
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {StyleSheet, View, Animated} from 'react-native';
import {IconButtonList} from '@components/common/IconButton';
import {IconEmployee, LanguageIcon} from '@components/common/icons/SvgIcon';
import {navigationRef} from '@navigation/navigationRef';
import {colors} from '@b2cmessenger/doppio-shared';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {
  useChangeLanguage,
  useGetCurrentLanguageName,
  useTranslation,
} from '@shared';
import {IS_ABLE_TO_REMOVE_ACCOUNT} from '@screens/RootNavigator';
import {changeAccountLanguage} from '@screens/Settings/changeAccountLanguage';
const {UserAvatarIcon} = icons;

export function SettingsScreen() {
  const {t} = useTranslation();
  const profile = useSelector(loginedUserSelectors.profile);
  const name = useMemo(() => {
    return `${profile?.firstname ? profile.firstname : ''}${
      profile?.lastname ? ` ${profile.lastname}` : ''
    }`;
  }, [profile]);

  const onBack = useCallback(() => {
    if (navigationRef.current?.canGoBack()) {
      navigationRef.current?.goBack();
    } else {
      navigationRef.current?.navigate('Dashboard');
    }
  }, []);

  const navbar = useMemo(
    () => (
      <Navbar
        title={t('Screens.Settings.accountSettings') || undefined}
        onBack={onBack}
      />
    ),
    [onBack, t],
  );

  const {currentLanguageName} = useGetCurrentLanguageName();
  const {changeLanguage} = useChangeLanguage({
    title: t('Screens.Settings.chooseYourLanguage'),
    onChange: (lng) => {
      changeAccountLanguage(lng);
    },
  });

  const data = useMemo(() => {
    const list = [];

    if (IS_ABLE_TO_REMOVE_ACCOUNT) {
      list.push({
        fill: colors.red,
        label: t('Screens.Settings.removeAccount'),
        labelStyle: {
          color: colors.red,
        },
        IconComponent: IconEmployee,
        accessibilityValue: {text: t('Screens.Settings.removeAccount')},
        caption: undefined,
        onPress: () => {
          navigationRef.current?.navigate('AccountRemoval');
        },
      });
    }

    list.push({
      fill: colors.black,
      label: t('Screens.Settings.changeLanguage'),
      IconComponent: LanguageIcon,
      accessibilityValue: {text: t('Screens.Settings.changeLanguage')},
      caption: currentLanguageName,
      onPress: changeLanguage,
    });

    return list;
  }, [changeLanguage, currentLanguageName, t]);

  return (
    <Screen style={styles.screen}>
      {navbar}
      <View style={{padding: Screen.PADDING_HORIZONTAL}}>
        <View style={styles.profile}>
          <Avatar avatar={profile?.avatarThumb} />
          <Spacer height={10} />
          {/* @ts-ignore */}
          <Animated.Text numberOfLines={1} style={Typography.mediumBody}>
            {name}
          </Animated.Text>
        </View>
      </View>
      <Spacer />
      <View style={{padding: Screen.PADDING_HORIZONTAL}}>
        <IconButtonList data={data} />
      </View>
    </Screen>
  );
}
const AVATAR_SIZE = 128;

function Avatar({avatar}: {avatar: string | null | undefined}) {
  const [avatarLoaded, setAvatarLoaded] = useState(false);
  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    Animated.timing(opacity, {
      toValue: avatarLoaded ? 0 : 1,
      duration: 250,
      useNativeDriver: true,
    }).start();
  }, [avatarLoaded, opacity]);
  useEffect(() => {
    setAvatarLoaded(Boolean(avatar));
  }, [avatar]);

  const avatarImageStyle = useMemo<
    ComponentProps<typeof Animated.Image>['style']
  >(
    () =>
      avatar
        ? {
            width: AVATAR_SIZE - 10,
            height: AVATAR_SIZE - 10,
            borderRadius: (AVATAR_SIZE - 10) / 2,
            opacity: 1,
            marginHorizontal: 5,
            position: 'absolute',
            top: 5,
          }
        : undefined,
    [avatar],
  );

  return (
    <View>
      {avatar ? (
        <Animated.Image
          style={avatarImageStyle}
          source={{uri: avatar}}
          onLoad={() => {
            setAvatarLoaded(true);
          }}
        />
      ) : undefined}
      <Animated.View style={{opacity, backgroundColor: colors.white}}>
        <UserAvatarIcon size={AVATAR_SIZE} />
      </Animated.View>
    </View>
  );
}

const styles = StyleSheet.create({
  screen: {
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 0,
  },
  profile: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  downloadAppBanner: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 44 + 16, // Navbar height + Spacer default height
    marginHorizontal: Screen.PADDING_HORIZONTAL,
  },
  listContainer: {paddingHorizontal: Screen.PADDING_HORIZONTAL},
});
