import React, {ComponentProps} from 'react';
import {
  Button,
  Spacer,
  TouchableCard,
  Typography,
} from '@b2cmessenger/doppio-components';
import {IconAppClient} from '@components/common/icons/SvgIcon';
import {StyleSheet, Text, View} from 'react-native';
import {useTranslation} from '@shared';

type DownloadAppBannerProps = {
  onPress: () => void;
  style?: ComponentProps<typeof TouchableCard>['style'];
};
function DownloadAppBanner({onPress, style}: DownloadAppBannerProps) {
  const {t} = useTranslation();

  return (
    <TouchableCard
      onPress={onPress}
      style={[styles.wrapper, style ? style : {}]}>
      <IconAppClient size={105} />
      <View style={styles.info}>
        <Text style={Typography.smallHeader}>
          {t('Screens.Dashboard.DownloadAppBanner.app')}
        </Text>
        <Spacer height={4} />
        <Text style={Typography.body}>
          {t('Screens.Dashboard.DownloadAppBanner.trackBonuses')}
        </Text>
        <Spacer height={8} />
        <Button.ExtraSmall
          title={t('Screens.Dashboard.DownloadAppBanner.download')}
          onPress={onPress}
        />
      </View>
    </TouchableCard>
  );
}

export default DownloadAppBanner;

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    marginLeft: 16,
  },
});
