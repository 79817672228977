import {api, LoginedUser} from '@b2cmessenger/doppio-core';
import {AxiosRequestConfig} from 'axios';
import {Logger} from '@b2cmessenger/doppio-shared';

export async function changeAccountLanguage(
  lng: string,
  requestConfig?: AxiosRequestConfig,
) {
  return api.default
    .patch<{user: LoginedUser}>(
      '/api/v2/user/profile',
      {locale: lng},
      requestConfig,
    )
    .then(api.getResponseData)
    .catch(e => {
      Logger.errorTag('checkInvite', e);
      throw api.parseErrorToHumanReadableMessage(e);
    });
}
