import type Say2B from '@b2cmessenger/api-specs';
export type {Say2B};

export type UserStampBalances = Say2B.Paths.V2UserStampsBalance.Get.Responses.$200;
export type Place = Say2B.Schemas.Place;
export type StampOperationResult = Say2B.Schemas.StampOperationResult;

type StampAwardOperationResult = Required<StampOperationResult>;

export type StampGiveJwtPayload = Say2B.Schemas.StampGiveJwtPayload;
export type StampAwardJwtPayload = Say2B.Schemas.StampAwardJwtPayload;
export type StampBalanceJwtPayload = Say2B.Schemas.StampBalanceJwtPayload;

export type UserOwn = Say2B.Schemas.UserOwn;

export function isAwardOperationResult(
  result: StampOperationResult,
): result is StampAwardOperationResult {
  const award = result as StampAwardOperationResult;
  return award.award_quantity !== undefined;
}

export type PlaceAppearanceType = Say2B.Schemas.PlaceAppearance;
