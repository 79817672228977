import {all} from 'redux-saga/effects';
import {
  Analytics,
  configureFirebaseApp,
  FirebaseRemoteConfig,
  sagas,
} from '@b2cmessenger/doppio-core';
import {parentSelectors} from '@store/reducer';
import loginJwtSaga from '@store/loginJwt.saga';

import Config from '@utils/Config';
import {FRCDefaults} from '@utils/FirebaseRemoteConfigContext';
import appTrackingSaga from '@store/appTracking.saga';
import localizationSaga from '@store/localization.saga';

configureFirebaseApp({
  apiKey: Config.FIREBASE_WEB_API_KEY,
  projectId: Config.FIREBASE_WEB_PROJECT_ID,
  appId: Config.FIREBASE_WEB_APP_ID,
  measurementId: Config.FIREBASE_WEB_MEASUREMENT_ID,
});
FirebaseRemoteConfig.setDefaults(FRCDefaults);

const {
  init: initSagas,
  notifications: notificationsSagas,
  auth: authSagas,
  analytics: analyticsSaga,
  network: networkSaga,
} = sagas;

export default function* rootSaga() {
  yield all([
    initSagas({
      parentSelectors: {
        init: parentSelectors.init,
        loginedUser: parentSelectors.loginedUser,
        network: parentSelectors.network,
      },
      appId: Config.APP_ID as Parameters<typeof initSagas>[0]['appId'],
    })(),
    networkSaga({
      parentSelectors: {
        network: parentSelectors.network,
      },
      appId: Config.APP_ID as Parameters<typeof initSagas>[0]['appId'],
    })(),
    authSagas({
      parentSelectors: {
        init: parentSelectors.init,
        loginedUser: parentSelectors.loginedUser,
      },
      appId: Config.APP_ID as Parameters<typeof authSagas>[0]['appId'],
    })(),
    notificationsSagas({
      onInteraction: () => {},
      parentSelectors: {
        loginedUser: parentSelectors.loginedUser,
      },
      appId: Config.APP_ID as Parameters<typeof notificationsSagas>[0]['appId'],
    })(),
    loginJwtSaga(),
    analyticsSaga({
      parentSelectors: {
        loginedUser: parentSelectors.loginedUser,
      },
      environmentLabelValue: Config.FIREBASE_ANALYTICS_ENV_LABEL,
      appId: Config.APP_ID as Parameters<typeof analyticsSaga>[0]['appId'],
      setupAnalyticsEnvironment: () => {
        return Analytics.initialize({
          firebase: {},
        });
      },
    })(),
    appTrackingSaga(),
    localizationSaga(),
  ]);
}
