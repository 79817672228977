import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

type AppTrackingState = {
  permissionStatus: null | boolean;
};

const initialState: AppTrackingState = {
  permissionStatus: null,
};

const {reducer, actions} = createSlice({
  name: 'appTracking',
  initialState: initialState,
  reducers: {
    setStatus(state, action: PayloadAction<{status: boolean}>) {
      state.permissionStatus = action.payload.status;
    },
  },
});

const selectSelf = (state: ReturnType<typeof reducer>) => state;
const permissionAsked = createDraftSafeSelector(
  selectSelf,
  (state) => state.permissionStatus !== null,
);

const selectors = {
  permissionAsked,
};

export {reducer, actions, selectors};
