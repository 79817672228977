import {
  createDraftSafeSelector,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import {slices} from '@b2cmessenger/doppio-core';
import type {Place} from '@typings/ApiSpec';
import {memoize} from 'lodash';

const placesAdapter = createEntityAdapter<Place>({
  selectId: ({id}) => id,
});

const {reducer, actions} = createSlice({
  name: 'userPlaces',
  initialState: placesAdapter.getInitialState(),
  reducers: {
    upsertMany: placesAdapter.upsertMany,
  },
  extraReducers: (builder) => {
    builder
      .addCase(slices.shared.actions.logout, () =>
        placesAdapter.getInitialState(),
      )
      .addCase(
        slices.shared.actions.applyStampOperationResult,
        (state, action) => {
          placesAdapter.upsertOne(state, action.payload.place);
        },
      )
      .addMatcher(slices.shared.matchers.isInitLikeAction, (state, action) => {
        if ('userPlaces' in action.payload && action.payload.userPlaces) {
          return placesAdapter.setAll(state, action.payload.userPlaces);
        }
        return state;
      });
  },
});

const placesSelectors = placesAdapter.getSelectors();

const selectSelf = (state: ReturnType<typeof reducer>) => state;
const placesSelector = createDraftSafeSelector(selectSelf, (state) =>
  placesSelectors.selectAll(state),
);
const placeSelector = createDraftSafeSelector(selectSelf, (state) =>
  memoize((placeId: number) => placesSelectors.selectById(state, placeId)),
);

const selectors = {
  places: placesSelector,
  place: placeSelector,
};

export {reducer, actions, selectors};
