import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  AwardErrorState,
  AwardState,
  IdleState,
  LoadingState,
  ScannerState,
  StampErrorState,
  StampState,
} from '@screens/Dashboard/shared';
import {Place} from '@typings/ApiSpec';

export type DashboardState =
  | IdleState
  | ScannerState
  | AwardState
  | AwardErrorState
  | StampState
  | StampErrorState
  | LoadingState;

const initialState = {
  status: 'idle',
};

const slice = createSlice({
  name: 'Dashboard',
  initialState: initialState as DashboardState,
  reducers: {
    idle: () => ({status: 'idle'}) as IdleState,
    scanner: () => ({status: 'scanner'}) as ScannerState,
    loading: {
      prepare: (placeId?: Place['id']) => ({
        payload: {
          placeId,
        },
      }),
      reducer: (state, action: PayloadAction<Omit<LoadingState, 'status'>>) =>
        ({status: 'loading', ...action.payload}) as LoadingState,
    },
    award: {
      prepare: (
        quantity: number,
        awardName: string,
        placeName: string,
        placeId: Place['id'],
      ) => ({
        payload: {
          quantity,
          awardName,
          placeName,
          placeId,
        },
      }),
      reducer: (state, action: PayloadAction<Omit<AwardState, 'status'>>) =>
        ({
          status: 'award',
          ...action.payload,
        }) as AwardState,
    },
    awardError: {
      prepare: (
        remains: number,
        awardQuantity: number,
        awardName: string | undefined,
        placeName: string | undefined,
        placeId: Place['id'],
      ) => ({
        payload: {
          remains,
          awardQuantity,
          awardName,
          placeName,
          placeId,
        },
      }),
      reducer: (
        state,
        action: PayloadAction<Omit<AwardErrorState, 'status'>>,
      ) =>
        ({
          status: 'awardError',
          ...action.payload,
        }) as AwardErrorState,
    },
    stamp: {
      prepare: (
        quantity: number,
        placeName: string,
        placeId: Place['id'],
        os?: string,
      ) => ({
        payload: {
          quantity,
          placeName,
          placeId,
          osBusinessDemo: os,
        },
      }),
      reducer: (state, action: PayloadAction<Omit<StampState, 'status'>>) =>
        ({
          status: 'stamp',
          ...action.payload,
        }) as StampState,
    },
    stampError: {
      prepare: (
        quantity: number,
        reason: string,
        placeName: string | undefined,
        placeId: Place['id'],
      ) => ({
        payload: {
          quantity,
          reason,
          placeName,
          placeId,
        },
      }),
      reducer: (
        state,
        action: PayloadAction<Omit<StampErrorState, 'status'>>,
      ) =>
        ({
          status: 'stampError',
          ...action.payload,
        }) as StampErrorState,
    },
    clearOsBusinessDemo(state) {
      if ('osBusinessDemo' in state && state.osBusinessDemo) {
        return {...state, osBusinessDemo: undefined};
      }
      return state;
    },
  },
});

export {slice};
