import {call, put} from 'redux-saga/effects';
import {setAppTrackingStatus} from '@store/actions';
import {setupTrackingTransparency} from '@utils/setupTrackingTransparency';

export default function* appTrackingSaga() {
  const permissionGranted = yield call(setupTrackingTransparency);
  yield put(
    setAppTrackingStatus({
      status: permissionGranted,
    }),
  );
}
