import {useMemo} from 'react';
import {StyleProp, ViewStyle} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {hooks} from '@b2cmessenger/doppio-core';
const {useIsKeyboardShown} = hooks;

const ScreenPrimaryButtonBottomPadding = 20;
export function useScreenPrimaryButtonStyle(
  style?: StyleProp<ViewStyle>,
): StyleProp<ViewStyle> {
  const {bottom} = useSafeAreaInsets();
  const isKeyboardShown = useIsKeyboardShown();
  return useMemo(
    () => [
      style,
      {
        marginBottom: isKeyboardShown
          ? ScreenPrimaryButtonBottomPadding
          : bottom || ScreenPrimaryButtonBottomPadding,
      },
    ],
    [bottom, isKeyboardShown, style],
  );
}
