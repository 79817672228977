import {DynamicLinksConfig} from '@utils/DynamicLinks/shared';

export function buildDownloadAppLink(accessToken?: string | null) {
  const {
    DynamicLinkDomain,
    DomainURIPrefix,
    AndroidPackageName,
    IosAppStoreId,
    IosBundleId,
  } = DynamicLinksConfig.Client;

  const link = accessToken
    ? [DynamicLinkDomain, 'auth', accessToken].join('/')
    : DynamicLinkDomain;

  return `${DomainURIPrefix}/?link=${link}/&apn=${AndroidPackageName}&ibi=${IosBundleId}&isi=${IosAppStoreId}&efr=0`;
}

export function buildBusinessAppLink() {
  const {
    DynamicLinkDomain,
    DomainURIPrefix,
    AndroidPackageName,
    IosAppStoreId,
    IosBundleId,
  } = DynamicLinksConfig.Business;

  const link = DynamicLinkDomain;
  return `${DomainURIPrefix}/?link=${link}/&apn=${AndroidPackageName}&ibi=${IosBundleId}&isi=${IosAppStoreId}&efr=0`;
}
export function buildBusinessWebAppLink() {
  return DynamicLinksConfig.Business.DynamicLinkDomain;
}
