import {useDispatch, useSelector} from 'react-redux';
import {loginJwtSelectors} from '@store/selectors';
import {useEffect} from 'react';
import {navigationRef} from '@navigation/navigationRef';
import {setLoginParsedJwt} from '@store/actions';

export function useCheckLoginParsedJwt() {
  const dispatch = useDispatch();
  const parsedJwt: ReturnType<typeof loginJwtSelectors.parsedJwt> = useSelector(
    loginJwtSelectors.parsedJwt,
  );
  useEffect(() => {
    if (parsedJwt !== null && parsedJwt.type !== null) {
      navigationRef.current?.navigate('Dashboard', {jwt: parsedJwt.jwt});
      dispatch(setLoginParsedJwt(null));
    }
  }, [dispatch, parsedJwt]);
}
