import {Screen as BaseScreen, useTheme} from '@b2cmessenger/doppio-components';
import React, {ComponentProps} from 'react';
import {ImageBackground, StyleSheet, View} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
// @ts-ignore
import BackgroundPatternWatermarkImage from '../../../assets/background_pattern_watermark.png';

type ScreenProps = ComponentProps<typeof BaseScreen> & {
  brandHeader?: JSX.Element | null;
};

function Screen(props: ScreenProps) {
  const {brandHeader, children, ...restProps} = props;
  const {colors} = useTheme();
  const {top} = useSafeAreaInsets();

  if (!brandHeader) {
    return <BaseScreen {...restProps} children={children} />;
  }

  return (
    <BaseScreen {...restProps}>
      <ImageBackground
        style={[
          styles.backgroundContainer,
          {
            backgroundColor: colors.brand,
          },
        ]}
        source={BackgroundPatternWatermarkImage}
        resizeMode="cover">
        <View
          style={[
            styles.brandHeaderBackgroundContainer,
            {marginTop: Math.max(20, top)},
          ]}>
          {brandHeader}
        </View>
        <View style={styles.screenContent}>{children}</View>
      </ImageBackground>
    </BaseScreen>
  );
}

// Hack
Screen.PADDING_HORIZONTAL = BaseScreen.PADDING_HORIZONTAL;
Screen.Heading = BaseScreen.Heading;
Screen.Description = BaseScreen.Description;

export {Screen};

const styles = StyleSheet.create({
  backgroundContainer: {
    display: 'flex',
    flex: 1,
  },
  brandHeaderBackgroundContainer: {
    margin: 20,
  },
  screenContent: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    borderTopStartRadius: 16,
    borderTopRightRadius: 16,
  },
});
