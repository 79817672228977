import * as jsrsasign from './jsrsasign/jsrsasign-jwths-min';
import {Logger} from '@b2cmessenger/doppio-shared';
import {
  StampAwardJwtPayload,
  StampBalanceJwtPayload,
  StampGiveJwtPayload,
} from '@typings/ApiSpec';

function parseJWT(data: string) {
  const {
    headerObj: header,
    payloadObj: payload,
    sigHex: signature,
  } = jsrsasign.KJUR.jws.JWS.parse(data);
  return {header, payload, signature};
}

function validateJWTHeader(header: {alg: string; typ: string}) {
  const {alg, typ} = header;
  return alg.toUpperCase() === 'HS256' && typ.toUpperCase() === 'JWT';
}

export type DemoStampGiveJwtPayload = StampGiveJwtPayload & {
  os: 'android' | 'ios' | 'web';
};

export type StampAwardParsedPayload = {
  type: StampAwardJwtPayload['t'];
  placeId: StampAwardJwtPayload['pid'];
  awardId: StampAwardJwtPayload['aid'];
  quantity: StampAwardJwtPayload['q'];
  issuedBy: StampAwardJwtPayload['uid'];
  jwt: string;
};

export type StampGiveJwtParsedPayload = {
  type: StampGiveJwtPayload['t'];
  placeId: StampGiveJwtPayload['pid'];
  quantity: StampGiveJwtPayload['q'];
  issuedBy: StampGiveJwtPayload['uid'];
  jwt: string;
};

export type DemoStampGiveJwtParsedPayload = StampGiveJwtParsedPayload & {
  os: string;
};

export type StampBalanceJwtParsedPayload = {
  type: StampBalanceJwtPayload['t'];
  placeId: StampBalanceJwtPayload['pid'];
  issuedBy: StampBalanceJwtPayload['uid'];
  jwt: string;
};

export type JwtParseResult =
  | StampAwardParsedPayload
  | StampGiveJwtParsedPayload
  | DemoStampGiveJwtParsedPayload
  | StampBalanceJwtParsedPayload
  | {type: null};

export function parse(data: string): JwtParseResult {
  try {
    const {header, payload} = parseJWT(data);
    if (validateJWTHeader(header) && payload) {
      if (isDemoStampGiveJwtPayload(payload)) {
        const {t: type, pid: placeId, q: quantity, uid: issuedBy, os} = payload;

        return {
          type,
          placeId,
          quantity,
          issuedBy,
          jwt: data,
          os,
        };
      } else if (isStampAwardJwtPayload(payload)) {
        const {
          t: type,
          pid: placeId,
          q: quantity,
          uid: issuedBy,
          aid: awardId,
        } = payload;

        return {
          type,
          placeId,
          awardId,
          quantity,
          issuedBy,
          jwt: data,
        };
      } else if (isStampGiveJwtPayload(payload)) {
        const {t: type, pid: placeId, q: quantity, uid: issuedBy} = payload;

        return {
          type,
          placeId,
          quantity,
          issuedBy,
          jwt: data,
        };
      } else if (isStampBalanceJwtPayload(payload)) {
        const {t: type, pid: placeId, uid: issuedBy} = payload;

        return {
          type,
          placeId,
          issuedBy,
          jwt: data,
        };
      }
    }
  } catch (e) {
    Logger.errorTag('parse', e);
  }

  return {type: null};
}

function isStampGiveJwtPayload(
  data: Record<any, any>,
): data is StampGiveJwtPayload {
  return (
    data &&
    data.t === 'sg' &&
    typeof data.pid === 'number' &&
    typeof data.q === 'number' &&
    typeof data.uid === 'number' &&
    typeof data.iat === 'number'
  );
}

function isDemoStampGiveJwtPayload(
  data: Record<any, any>,
): data is DemoStampGiveJwtPayload {
  return isStampGiveJwtPayload(data) && typeof data.os === 'string';
}

function isStampAwardJwtPayload(
  data: Record<any, any>,
): data is StampAwardJwtPayload {
  return (
    data &&
    data.t === 'sa' &&
    typeof data.pid === 'number' &&
    typeof data.q === 'number' &&
    typeof data.aid === 'number' &&
    typeof data.uid === 'number' &&
    typeof data.iat === 'number'
  );
}

function isStampBalanceJwtPayload(
  data: Record<any, any>,
): data is StampBalanceJwtPayload {
  return data && data.t === 'sb' && typeof data.pid === 'number';
}
