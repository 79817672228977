function getStampSectionType(idx: number, list: Array<unknown>) {
  if (list.length === 1) {
    return 'single';
  } else if (idx === 0) {
    return 'first';
  } else if (idx === list.length - 1) {
    return 'last';
  } else {
    return 'row';
  }
}

export function getStampSections(
  stamps: number,
  awardCost: number,
  stampPerRow: number,
) {
  const minRowNumber = Math.max(1, Math.ceil(awardCost / stampPerRow));
  const numberOfRows = Math.max(minRowNumber, Math.ceil(stamps / stampPerRow));

  return Array.from({
    length: numberOfRows,
  }).map((_, rowIdx, list) => {
    const numberOfReceivedStampsRerRow =
      (rowIdx + 1) * stampPerRow > stamps
        ? Math.max(0, stamps - rowIdx * stampPerRow)
        : stampPerRow;
    return [
      numberOfReceivedStampsRerRow,
      rowIdx,
      getStampSectionType(rowIdx, list),
    ];
  });
}
