import React from 'react';

import {StyleSheet, Text, View} from 'react-native';
import {Spacer, Typography} from '@b2cmessenger/doppio-components';
import {IconPosTabled} from '@components/common/icons/SvgIcon';
import {useTranslation} from '@shared';

export function NoAwardedPlacesPlaceholder() {
  const {t} = useTranslation();

  return (
    <View style={styles.wrapper}>
      <IconPosTabled size={108} />
      <Spacer height={20} />
      <Text style={Typography.smallHeader}>
        {t('Screens.Dashboard.noStampsYet')}
      </Text>
      <Spacer height={8} />
      <Text style={styles.description}>
        {t('Screens.Dashboard.scanQrAtTheCheckout')}
      </Text>
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  description: {
    ...StyleSheet.flatten(Typography.body),
    textAlign: 'center',
  },
});
