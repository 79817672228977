import Config from '@utils/Config';

export const DynamicLinksConfig = {
  Business: {
    DynamicLinkDomain: Config.DYNAMIC_LINK_DOMAIN_BUSINESS,
    DomainURIPrefix: Config.DOMAIN_URI_PREFIX_BUSINESS,
    AndroidPackageName: Config.ANDROID_PACKAGE_NAME_BUSINESS,
    IosAppStoreId: Config.IOS_APP_STORE_ID_BUSINESS,
    IosBundleId: Config.IOS_BUNDLE_ID_BUSINESS,
  },
  Client: {
    DynamicLinkDomain: Config.DYNAMIC_LINK_DOMAIN_CLIENT,
    DomainURIPrefix: Config.DOMAIN_URI_PREFIX_CLIENT,
    AndroidPackageName: Config.ANDROID_PACKAGE_NAME_CLIENT,
    IosAppStoreId: Config.IOS_APP_STORE_ID_CLIENT,
    IosBundleId: Config.IOS_BUNDLE_ID_CLIENT,
  },
};
