/**
 * @format
 */
import * as React from 'react';
import {AppRegistry} from 'react-native';
import App from './src/App.tsx';
import {name as appName} from './app.json';

import {Logger} from '@b2cmessenger/doppio-shared';
import Config from '@utils/Config';

Logger.setSeverity(Config.LOG_SEVERITY);

AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  rootTag: document.getElementById('root'),
});
