import {BarCodeScanner, BarCodeScannerProps} from 'expo-barcode-scanner';
import React, {
  ComponentProps,
  PropsWithChildren,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Button,
  Pusher,
  Spacer,
  Typography,
  useTheme,
  Screen,
} from '@b2cmessenger/doppio-components';
import {StyleSheet, useWindowDimensions, View} from 'react-native';
import Svg, {Path} from 'react-native-svg';
import {Camera} from '@components/common/Camera';
import {isTablet} from 'react-native-device-info';
import {colors} from '@b2cmessenger/doppio-shared';
import {Icon} from '@components/common/icons';
import {Text} from 'react-native-paper';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {Camera as EXCamera} from 'expo-camera';
import {useTranslation} from '@shared';
import {SmallBanner, useOnPressDownloadApp} from '@screens/Dashboard/Dashboard';

const _isTablet = isTablet();
const Margin = _isTablet ? 22 : 16;

export function QRCodeScanner(
  props: ComponentProps<typeof QRCodeScannerContent>,
) {
  const [hasPermission, setHasPermission] = useState<null | boolean>(null);
  useEffect(() => {
    (async () => {
      const {status} = await EXCamera.requestCameraPermissionsAsync();
      setHasPermission(status === 'granted');
    })();
  }, []);

  return hasPermission ? (
    <QRCodeScannerContent {...props} />
  ) : (
    <View
      style={[
        StyleSheet.absoluteFill,
        {paddingHorizontal: Screen.PADDING_HORIZONTAL},
      ]}>
      <Pusher />
      <Text style={styles.text}>
        {hasPermission === null
          ? 'Requesting camera permissions'
          : 'No camera access'}
      </Text>
      <Pusher />
      <Button.Secondary
        title={'Cancel'}
        onPress={props.onCancel}
        style={{paddingHorizontal: Margin}}
      />
      <Spacer height={props.bottomGutter} />
    </View>
  );
}

function QRCodeScannerContent({
  onBarCodeScanned,
  onCancel,
  scanned,
  bottomGutter,
}: Pick<BarCodeScannerProps, 'onBarCodeScanned'> & {
  scanned?: boolean;
  onCancel?: () => void;
  bottomGutter?: number;
}) {
  const {colors: themeColors} = useTheme();
  const {width, height} = useWindowDimensions();
  const safeAreaInsets = useSafeAreaInsets();
  const spacerTopHeight = useMemo(
    () => safeAreaInsets.top + 20,
    [safeAreaInsets.top],
  );
  const isLandscape = useMemo(() => width > height, [width, height]);
  const size = useMemo(
    () =>
      Math.min(
        width - Margin * 2 * (_isTablet ? 4 * 2 : 2),
        (height -
          spacerTopHeight -
          Margin * 2 -
          (isLandscape ? -40 : Margin * 2)) /
          2,
      ),
    [height, isLandscape, spacerTopHeight, width],
  );
  const _styles = useMemo(() => {
    return StyleSheet.create({
      container: {
        width: size + Margin * 2,
        height: size + Margin * 2,
      },
      barCode: {
        width: size,
        height: size,
        margin: Margin,
        borderRadius: 12,
        overflow: 'hidden',
      },
      cancelButton: {
        width: size + Margin * 2,
      },
    });
  }, [size]);

  const {t} = useTranslation();
  const _doesntWork = t(
    'Screens.Dashboard.Scanner.theScannerFeatureOnTheWebVersionMightNotFunctionOn',
  );
  const onPressDownloadApp = useOnPressDownloadApp();

  return (
    <View style={styles.wrapper}>
      <Spacer height={spacerTopHeight} />
      <View style={styles.textWrapper}>
        <Icon name={'qr'} size={28} style={{color: themeColors.brand}} />
        <Text style={[Typography.mediumBody, styles.qrTextIcon]}>
          {t('Screens.Dashboard.Scanner.scanQr')}
        </Text>
      </View>
      <Spacer height={6} />
      <View style={_styles.container}>
        <Bounds>
          <Camera
            barCodeScannerSettings={{
              barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
            }}
            onBarCodeScanned={scanned ? undefined : onBarCodeScanned}
            style={_styles.barCode}
            ratio={'1:1'}
          />
        </Bounds>
      </View>
      <Spacer />
      <Pusher />
      <>
        <Text
          style={[
            Typography.mediumBody,
            {color: themeColors.darkgray, textAlign: 'center'},
          ]}>
          {_doesntWork}
        </Text>
        <Spacer />
        <SmallBanner
          onPress={onPressDownloadApp}
          smallBannerText={t('Screens.Dashboard.DownloadAppBanner.app')}
        />
      </>
      <Pusher />
      <Button.Secondary
        title={'Cancel'}
        style={_styles.cancelButton}
        onPress={onCancel}
      />
      <Spacer height={bottomGutter} />
    </View>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  qrTextIcon: {
    color: colors.black,
    marginLeft: 5,
  },
  textWrapper: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: colors.black,
  },
  text: {
    ...StyleSheet.flatten(Typography.mediumBody),
    textAlign: 'center',
  },
});

function Bounds({children}: PropsWithChildren<{}>) {
  return (
    <View style={StyleSheet.absoluteFill}>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" style={tr}>
        <Path
          d="M44 44L44 20C44 11.1634 36.8366 4 28 4L4 4"
          stroke="#0057FF"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </Svg>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" style={tl}>
        <Path
          d="M44 4H20C11.1634 4 4 11.1634 4 20V44"
          stroke="#0057FF"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </Svg>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" style={bl}>
        <Path
          d="M4 4L4 28C4 36.8366 11.1634 44 20 44L44 44"
          stroke="#0057FF"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </Svg>
      <Svg width="48" height="48" viewBox="0 0 48 48" fill="none" style={br}>
        <Path
          d="M4 44L28 44C36.8366 44 44 36.8366 44 28L44 4"
          stroke="#0057FF"
          strokeWidth="8"
          strokeLinecap="round"
        />
      </Svg>
      {children}
    </View>
  );
}

const {tr, tl, bl, br} = StyleSheet.create({
  tr: {position: 'absolute', top: 0, right: 0},
  tl: {position: 'absolute', top: 0, left: 0},
  bl: {position: 'absolute', bottom: 0, left: 0},
  br: {position: 'absolute', bottom: 0, right: 0},
});
