import {useTranslation} from '@shared';
import {Typography, useTheme} from '@b2cmessenger/doppio-components';
import React, {useMemo} from 'react';
import {Text} from 'react-native';

export function isValidEmail(email) {
  return new RegExp(/\S+@\S+\.\S+/).test(email);
}

export function EmailSignInError({error}: {error: Error | string}) {
  const {t} = useTranslation();
  const {colors} = useTheme();
  const _msg = useMemo(
    () =>
      (typeof error === 'string' ? error : error?.message) ||
      t('Shared.ErrorCard.anErrorHasOccurred'),
    [t, error],
  );

  return (
    <>
      <Text style={[Typography.smallBody, {color: colors.red}]}>{_msg}</Text>
    </>
  );
}
