export function createScreenTestID(screenName: string) {
  return `${screenName}__Screen`;
}

export function createButtonTestID(screenName: string, buttonName: string) {
  return `${createScreenTestID(screenName)}--${buttonName}Button`;
}

export function createTextInputTestID(screenName: string, buttonName: string) {
  return `${createScreenTestID(screenName)}--${buttonName}TextInput`;
}

export function createElementTestID(screenName: string, elementName: string) {
  return `${createScreenTestID(screenName)}--${elementName}`;
}

export function createScreenTestIDS<
  ButtonKey extends string,
  InputKey extends string,
  ElementKey extends string
>(
  screenName: string,
  {
    buttons = [],
    inputs = [],
    elements = [],
  }: {buttons?: ButtonKey[]; inputs?: InputKey[]; elements?: ElementKey[]},
) {
  return {
    screen: createScreenTestID(screenName),
    buttons: buttons.reduce(
      (memo, button) => ({
        ...memo,
        [button]: createButtonTestID(screenName, button),
      }),
      {} as Record<ButtonKey, string>,
    ),
    inputs: inputs.reduce(
      (memo, input) => ({
        ...memo,
        [input]: createTextInputTestID(screenName, input),
      }),
      {} as Record<InputKey, string>,
    ),
    elements: elements.reduce(
      (memo, element) => ({
        ...memo,
        [element]: createElementTestID(screenName, element),
      }),
      {} as Record<ElementKey, string>,
    ),
  };
}
