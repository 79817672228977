import {api} from '@b2cmessenger/doppio-core';
import {StampOperationResult} from '@typings/ApiSpec';
import {AxiosError, AxiosRequestConfig} from 'axios';
import {ServerExceptionError} from '@b2cmessenger/doppio-core/src/types/Error';

export function execute(
  action: 'give' | 'award',
  {
    placeId,
    issuedBy,
    jwt,
  }: {
    placeId: number;
    issuedBy: number;
    jwt: string;
  },
  requestConfig?: AxiosRequestConfig,
) {
  const data = new FormData();
  data.append('jwt', jwt);

  return api.default
    .post<StampOperationResult>(
      `/api/v2/place/${String(placeId)}/stamps/employee/${String(
        issuedBy,
      )}/${action}`,
      data,
      requestConfig,
    )
    .then(api.getResponseData)
    .catch((e: AxiosError) => {
      if (e.response) {
        if (
          e.response.status === 422 &&
          action === 'award' &&
          e.response.data &&
          typeof e.response.data.balance === 'number' &&
          typeof e.response.data.balance === 'number'
        ) {
          return {
            error: {
              balance: e.response.data.balance as number,
              remains: e.response.data.remains as number,
            },
          };
        } else if (e.response.status === 400) {
          return {
            error: api.parseErrorToHumanReadableMessage(e),
            code: parseErrorCode(e),
          };
        }
      }

      return {error: e.message};
    });
}

export function parseErrorCode(error: AxiosError<ServerExceptionError>) {
  return error.response?.data.code;
}
