import {localization} from '@b2cmessenger/doppio-core';
import enResource from './locales/en/translation.json';
import esResource from './locales/es/translation.json';

export const bundles = [
  {lng: 'en', ns: 'translation', resource: enResource},
  {lng: 'es', ns: 'translation', resource: esResource},
];

export const LANGUAGES = [
  {
    lng: 'en',
    name: 'English',
  },
  {
    lng: 'es',
    name: 'Español',
  },
];

export default localization;
