import {parseDynamicLink} from '@utils/DynamicLinks/handleDynamicLink';

function onLink() {
  return () => {};
}
async function getInitialLink() {
  return null;
}
async function buildLink() {
  return '';
}
async function buildShortLink() {
  return '';
}

async function resolveLink(link: string) {
  const parsedDL = parseDynamicLink(link);
  if (parsedDL.path && parsedDL.params?.has('link')) {
    const url = parsedDL.params.get('link');
    if (url) {
      return {url};
    }
  }

  throw new Error('Unrecognizable link');
}

export default {onLink, getInitialLink, buildLink, buildShortLink, resolveLink};
