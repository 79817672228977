import React, {useMemo} from 'react';
import {Image, ImageProps, ViewProps} from 'react-native';
import {IconPlaceLogo} from '@components/common/icons/SvgIcon';

type Props = {
  uri: string;
  size?: number;
  fill?: string;
} & Pick<ImageProps, 'style'> &
  Pick<ViewProps, 'testID'>;

export function PlaceLogo({
  uri,
  size = 28,
  style: _style,
  fill,
  ...rest
}: Props) {
  const style = useMemo(
    () => [_style, size ? {width: size, height: size} : undefined],
    [_style, size],
  );
  const imageSource = useMemo(() => ({uri}), [uri]);
  return uri ? (
    <Image source={imageSource} style={style} {...rest} />
  ) : (
    <IconPlaceLogo size={size} style={_style} fill={fill} {...rest} />
  );
}
