import {useLayoutEffect, useState} from 'react';
import {colors} from '@b2cmessenger/doppio-shared';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {useSelector} from 'react-redux';
import {userPlacesSelectors} from '@store/selectors';
export function useBrandColorFromSinglePlace() {
  const [brandColor, setBrandColor] = useState(colors.brand);
  const places = useSelector(userPlacesSelectors.places);

  useLayoutEffect(() => {
    function updateBrandColor(
      data: ReturnType<typeof PlaceAppearance.storage.getData>,
    ) {
      if (data && Object.values(data).length === 1) {
        setBrandColor(Object.values(data)[0].color);
      } else {
        setBrandColor(colors.brand);
      }
    }
    updateBrandColor(PlaceAppearance.storage.getData());
    const unsubscribe = PlaceAppearance.storage.subscribe(() => {
      updateBrandColor(PlaceAppearance.storage.getData());
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return {brandColor: places.length === 1 ? brandColor : colors.brand};
}
