import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {
  StampAwardParsedPayload,
  StampBalanceJwtParsedPayload,
  StampGiveJwtParsedPayload,
} from '@utils/JWT';

type State =
  | StampAwardParsedPayload
  | StampGiveJwtParsedPayload
  | StampBalanceJwtParsedPayload;
const initialState = {} as State | {};

export const {reducer, actions} = createSlice({
  name: 'loginJwt',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<State | null>) => {
      return action.payload
        ? {
            ...action.payload,
            __persisted_at: Date.now(),
          }
        : {};
    },
  },
});

export const selectors = {
  parsedJwt: createDraftSafeSelector(
    (state: ReturnType<typeof reducer>) => state,
    (jwt) => ('jwt' in jwt && jwt.type !== null ? jwt : null),
  ),
};
