import React, {
  ComponentProps,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {RootNavigatorScreenProps} from '@navigation/config';
import {useDispatch, useSelector} from 'react-redux';
import {
  BModal,
  Button,
  Card,
  Spacer,
  ThemeProvider,
  TouchableCard,
  Typography,
} from '@b2cmessenger/doppio-components';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {
  Linking,
  Modal,
  ModalProps,
  Platform,
  StyleSheet,
  Text,
  View,
  ViewStyle,
} from 'react-native';
import {StampControl} from '@screens/Dashboard/components/StampControl';
import {useDashboardStampState} from '@screens/Dashboard/hooks/useDashboardStampState';
import {
  stampAwardsSelectors,
  stampsSelectors,
  userPlacesSelectors,
  loginedUserSelectors,
  networkSelectors,
} from '@store/selectors';
import {RootState} from '@store/reducer';
import LoggedInUserNavbar from '@components/common/LoggedInUserNavbar';
import {AwardedStampedPlace} from './shared';

import DownloadAppBanner from '@screens/Dashboard/components/DownloadAppBanner';
import {Alert} from '@components/common/Alert';
import {
  buildBusinessAppLink,
  buildBusinessWebAppLink,
  buildDownloadAppLink,
} from '@utils/DynamicLinks/buildDownloadAppLink';
import {PlacesAwardsList} from '@screens/Dashboard/components/PlacesAwardsList';
import PlaceAwardsDetails from './components/PlaceAwardsDetails';
import FirebaseRemoteConfigContext from '@utils/FirebaseRemoteConfigContext';
import NetworkError, {
  NetworkErrorContentHeight,
} from '@screens/Dashboard/components/NetworkError';
import {
  NavbarOptions,
  useNavbarOptions,
} from '@components/common/NavbarOptions';
import {QRCodeScanner} from '@screens/Dashboard/components/QRCodeScanner';
import {useTranslation} from '@shared';
import {useCheckLoginParsedJwt} from '@screens/Dashboard/hooks/useCheckLoginParsedJwt';
import {Dictionary} from '@reduxjs/toolkit';
import {DashboardState} from '@screens/Dashboard/slice';
import {navigationRef} from '@navigation/navigationRef';
import SignInBanner from '@screens/Dashboard/components/SignInBanner';
import {Balances} from '@store/slices/stamps';
import {Place} from '@typings/ApiSpec';
import {IconAppClient} from '@components/common/icons/SvgIcon';
import {Screen} from '@components/common/Screen';
import {Portal} from 'react-native-paper';
import {useBrandColorFromSinglePlace} from '@screens/Dashboard/hooks/useBrandColorFromSinglePlace';
import {checkAndUpdatePlaceAppearance} from '@utils/placeAppearance';
import {apiMethods} from '@b2cmessenger/doppio-core';
import {actions as userPlacesActions} from '@store/slices/userPlaces';
import {useSetThemeColorMetaTag} from '@components/hooks/useSetThemeColorMetaTag';

const TIMOUT_TO_SHOW_BUSINESS_DEMO_MODAL = 3000;

export function DashboardScreen({
  route,
  navigation,
}: RootNavigatorScreenProps<'Dashboard'>) {
  useCheckLoginParsedJwt();
  const {
    navbarOptionsListData,
    onPressLoggedInOptions,
    navbarOptionsVisible,
    setNavbarOptionsVisible,
  } = useNavbarOptions();
  const navbar = useMemo(
    () => <LoggedInUserNavbar onPressOptions={onPressLoggedInOptions} />,
    [onPressLoggedInOptions],
  );

  const {banner} = useDashboardLoggedInBanner();

  const clearParams = useCallback(() => {
    navigation.setParams({jwt: undefined});
  }, [navigation]);

  return (
    <Screen style={styles.screen}>
      {navbar}
      <DashboardContent
        clearParams={clearParams}
        jwt={route?.params?.jwt}
        banner={banner}
        smallBanner={null}
      />
      <BModal
        visible={navbarOptionsVisible}
        onRequestClose={() => setNavbarOptionsVisible(false)}>
        <NavbarOptions data={navbarOptionsListData} />
      </BModal>
    </Screen>
  );
}

export function DashboardGuest({
  route,
  navigation,
}: RootNavigatorScreenProps<'Dashboard'>) {
  const clearParams = useCallback(() => {
    navigation.setParams({jwt: undefined});
  }, [navigation]);
  const {smallBanner} = useDashboardSignInBanner();
  const {brandColor} = useBrandColorFromSinglePlace();
  useUpdateCachedPlacesForNonLoggedIn();
  useSetThemeColorMetaTag(brandColor);

  return (
    <ThemeProvider colors={{brand: brandColor}}>
      <Screen style={[styles.screen]} brandHeader={smallBanner}>
        <DashboardContent
          clearParams={clearParams}
          jwt={route?.params?.jwt}
          banner={null}
          smallBanner={null}
        />
      </Screen>
    </ThemeProvider>
  );
}

type DashboardBannerProps = {
  banner: JSX.Element | null;
  smallBanner: JSX.Element | null;
};

function DashboardContent({
  jwt,
  clearParams,
  banner,
  smallBanner,
}: {
  jwt: string | undefined;
  clearParams: () => void;
} & DashboardBannerProps) {
  const {
    stampedPlace,
    setStampedPlaceId,
    awardedStampedPlaces,
    awardCosts,
    isOffline,
    onScanError,
  } = useDashboardCommon();

  const {
    state,
    scanned,
    onCodeScanned,
    displayScanButton,
    displayScanner,
    executeJwtOperation,
    loading,
    clearOsBusinessDemo,
  } = useDashboardStampState({
    onScanError: onScanError,
    setStampedPlaceId,
  });
  useEffect(() => {
    if (jwt) {
      clearParams?.call(null);
      executeJwtOperation(jwt).then((data) => {
        if (data && data.type === 'sb') {
          setStampedPlaceId(data.placeId);
        }
      });
    }
  }, [executeJwtOperation, clearParams, jwt, setStampedPlaceId]);

  const {
    bottomMostSpacerHeight,
    paddedListStyle,
    stampControlStyle,
    stampControlVisible,
  } = useDashboardStyles({isOffline, state});

  const onStateReset = useCallback(() => {
    displayScanButton.call(null);
  }, [displayScanButton]);

  const [osBusinessDemo, setOsBusinessDemo] = useState<null | string>(null);
  useEffect(() => {
    if (state?.osBusinessDemo) {
      const t = setTimeout(() => {
        setOsBusinessDemo(state.osBusinessDemo);
        clearOsBusinessDemo();
      }, TIMOUT_TO_SHOW_BUSINESS_DEMO_MODAL);
      return () => clearTimeout(t);
    }
  }, [clearOsBusinessDemo, state?.osBusinessDemo]);

  return (
    <>
      <PlacesWithBanner
        awardedStampedPlaces={awardedStampedPlaces}
        paddingBottom={bottomMostSpacerHeight}
        awardCosts={awardCosts}
        onPlacePress={(data) => {
          setStampedPlaceId(data.id);
        }}
        listHeaderComponent={banner}
        contentContainerStyle={paddedListStyle}
        smallBanner={smallBanner}
      />
      <StampControlWithScanner
        offline={isOffline}
        style={stampControlStyle}
        stampControlVisible={stampControlVisible}
        onScanQR={displayScanner}
        loading={loading}
        state={state}
        onStateReset={onStateReset}
        scanned={scanned}
        onCodeScanned={onCodeScanned}
        appearancePlaceId={
          awardedStampedPlaces?.length === 1
            ? awardedStampedPlaces[0].id
            : undefined
        }
      />
      <Portal>
        {stampedPlace ? ( // stampedPlace !== null  only when awardedStampedPlaces?.length > 1
          <StampedPlaceModal
            stampedPlace={stampedPlace}
            onRequestClose={() => setStampedPlaceId(null)}
            paddingBottom={bottomMostSpacerHeight}
            awardCosts={awardCosts}
          />
        ) : null}
        {osBusinessDemo ? (
          <BModal
            visible
            onRequestClose={() => setOsBusinessDemo(null)}
            onDismiss={() => setOsBusinessDemo(null)}>
            <BusinessDemoCard
              onPress={() => {
                const url =
                  osBusinessDemo === 'web'
                    ? buildBusinessWebAppLink()
                    : buildBusinessAppLink();
                Linking.openURL(url);
              }}
            />
          </BModal>
        ) : null}
      </Portal>
    </>
  );
}

function BusinessDemoCard(props: {onPress: () => void}) {
  const {t} = useTranslation();

  return (
    <Card shadowEnabled={false}>
      <Text style={[Typography.header, {textAlign: 'center'}]}>
        {t('Screens.Dashboard.BusinessDemoCard.congratulations')}
      </Text>
      <Spacer />
      <Text style={[Typography.mediumBody]}>
        {t(
          'Screens.Dashboard.BusinessDemoCard.youveReceivedYourFirstTestStamp',
        )}
      </Text>
      <Spacer />
      <Text style={[Typography.mediumBody]}>
        {t(
          'Screens.Dashboard.BusinessDemoCard.toKeepExploringTheAppSimplyReturnByTappingTheButto',
        )}
      </Text>
      <Spacer />
      <Button
        title={t(
          'Screens.Dashboard.BusinessDemoCard.returnToBusinessApplication',
        )}
        onPress={props.onPress}
      />
    </Card>
  );
}

function useDashboardSignInBanner(): DashboardBannerProps {
  const {t} = useTranslation();

  const banner = useMemo(
    () => (
      <SignInBanner onPress={() => navigationRef.current?.navigate('SignIn')} />
    ),
    [],
  );
  const smallBanner = useMemo(
    () => (
      <SmallBanner
        onPress={() => navigationRef.current?.navigate('SignIn')}
        smallBannerText={t('Screens.SignIn.Banner.small')}
      />
    ),
    [t],
  );

  return {
    banner,
    smallBanner,
  };
}

export function useOnPressDownloadApp() {
  const {t} = useTranslation();
  const accessToken = useSelector(loginedUserSelectors.accessToken);
  const onPressDownloadApp = useCallback(async () => {
    const url = buildDownloadAppLink(accessToken as string);
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      Alert.alert(
        t('AppBanner.ErrorOccurred'),
        t('AppBanner.tryLater') || undefined,
      );
    }
  }, [accessToken, t]);

  return onPressDownloadApp;
}

function useDashboardLoggedInBanner(): DashboardBannerProps {
  const {displayDownloadAppBanner} = useContext(FirebaseRemoteConfigContext);
  const onPressDownloadApp = useOnPressDownloadApp();

  const banner = useMemo(
    () =>
      Platform.OS === 'web' && displayDownloadAppBanner ? (
        <>
          <DownloadAppBanner onPress={onPressDownloadApp} />
          <Spacer />
        </>
      ) : null,
    [displayDownloadAppBanner, onPressDownloadApp],
  );
  const smallBanner = useMemo(() => null, []);

  return {
    banner,
    smallBanner,
  };
}

type AwardCosts = Dictionary<number | undefined>;
function StampedPlaceModal(props: {
  stampedPlace: AwardedStampedPlace | null;
  onRequestClose: () => void;
  paddingBottom: number;
  awardCosts: AwardCosts;
}) {
  return props.stampedPlace ? (
    <BModal visible onRequestClose={props.onRequestClose}>
      <PlaceAwardsDetails
        contentContainerStyle={{
          paddingHorizontal: Screen.PADDING_HORIZONTAL,
          paddingBottom: props.paddingBottom,
        }}
        place={props.stampedPlace}
        awardCost={
          props.awardCosts[props.stampedPlace.id] ||
          props.stampedPlace?.awardCost ||
          0
        }
      />
    </BModal>
  ) : null;
}

export function SmallBanner(props: {
  onPress: () => void;
  smallBannerText: string;
}) {
  return (
    <View
      style={{
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <TouchableCard
        onPress={props.onPress}
        style={[bannerStyles.wrapper]}
        paddingVertical={
          Platform.OS === 'web'
            ? TouchableCard.PADDING_HORIZONTAL
            : TouchableCard.PADDING_VERTICAL
        }>
        <IconAppClient height={20} width={20} />
        <View style={bannerStyles.info}>
          <Text style={Typography.body}>{props.smallBannerText}</Text>
        </View>
      </TouchableCard>
    </View>
  );
}

function PlacesWithBanner(props: {
  awardedStampedPlaces: AwardedStampedPlace[];
  paddingBottom: number;
  awardCosts: AwardCosts;
  onPlacePress: (data: AwardedStampedPlace) => void;
  listHeaderComponent: JSX.Element | null;
  contentContainerStyle: {
    // Padding to scroll over stamp control
    paddingBottom: number;
    paddingHorizontal: number;
  };
  smallBanner: JSX.Element | null;
}) {
  if (props.awardedStampedPlaces?.length === 1) {
    return (
      <View style={{flex: 1, flexGrow: 1}}>
        {props.smallBanner}
        <PlaceAwardsDetails
          contentContainerStyle={{
            paddingHorizontal: Screen.PADDING_HORIZONTAL,
            paddingBottom: props.contentContainerStyle.paddingBottom,
          }}
          place={props.awardedStampedPlaces[0]}
          awardCost={
            props.awardCosts[props.awardedStampedPlaces[0].id] ||
            props.awardedStampedPlaces[0]?.awardCost ||
            0
          }
          scrollEnabled={true}
          placeLogoSize={70}
        />
      </View>
    );
  }

  return (
    <PlacesAwardsList
      onPlacePress={props.onPlacePress}
      places={props.awardedStampedPlaces}
      ListHeaderComponent={props.listHeaderComponent}
      contentContainerStyle={props.contentContainerStyle}
    />
  );
}

const bannerStyles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: 200,
  },
  info: {
    marginLeft: 16,
  },
});

function StampControlWithScanner(props: {
  offline: boolean;
  style: ViewStyle;
  stampControlVisible: boolean;
  onScanQR: () => void;
  loading: boolean;
  state: DashboardState;
  onStateReset: () => void;
  scanned: boolean;
  onCodeScanned: ({data}: {data: string}) => Promise<void>;
  appearancePlaceId?: number;
}) {
  return (
    <>
      {props.offline ? <NetworkError style={props.style} /> : null}
      {props.stampControlVisible && !props.offline ? (
        <StampControl
          style={props.style}
          onScanQR={props.onScanQR}
          loading={props.loading || props.state.status === 'scanner'}
          state={
            props.state.status === 'idle' || props.state.status === 'scanner'
              ? undefined
              : props.state
          }
          onStateReset={props.onStateReset}
          appearancePlaceId={props.appearancePlaceId}
        />
      ) : null}
      <Scanner
        animationType="slide"
        onBarCodeScanned={props.scanned ? undefined : props.onCodeScanned}
        visible={props.state.status === 'scanner' && !props.offline}
        onRequestClose={props.onStateReset}
      />
    </>
  );
}

function useUpdateCachedPlacesForNonLoggedIn() {
  const isLoggedIn = useSelector(loginedUserSelectors.isLoggedIn);
  const dispatch = useDispatch();
  const balances = useSelector(stampsSelectors.balancesMap);

  useEffect(() => {
    if (!isLoggedIn && balances && Object.keys(balances).length > 0) {
      async function updatePlaceAppearances(_placeIds: number[]) {
        for (let i = 0; i < _placeIds.length; i++) {
          await checkAndUpdatePlaceAppearance(_placeIds[i], true);
        }
      }
      const placeIds = Object.keys(balances).map(Number);
      apiMethods
        .placeSearch({
          ids: placeIds,
        })
        .then((data) => {
          dispatch(userPlacesActions.upsertMany(data.places));
        });
      updatePlaceAppearances(placeIds);
    }
  }, [balances, dispatch, isLoggedIn]);
}

function useDashboardCommon() {
  const isOffline = useSelector(networkSelectors.isOffline) as boolean;
  const places = useSelector(userPlacesSelectors.places);
  const balances = useSelector(stampsSelectors.balancesMap);
  const awardCosts = useSelector(
    useCallback(
      (state: RootState) => stampAwardsSelectors.allPlacesAwardCost(state)(0),
      [],
    ),
  );
  const [stampedPlaceId, setStampedPlaceId] = useState<null | number>(null);
  const awardedStampedPlaces = useMemo(
    () => getAwardedStampedPlaces({awardCosts, balances, places}),
    [awardCosts, balances, places],
  );

  const stampedPlace = useMemo(() => {
    return (
      (stampedPlaceId &&
        awardedStampedPlaces.length > 1 &&
        awardedStampedPlaces.find((p) => p.id === stampedPlaceId)) ||
      null
    );
  }, [stampedPlaceId, awardedStampedPlaces]);

  const onScanError = useCallback((title: string, message: string) => {
    Alert.alert(title, message, [{text: 'OK'}]);
  }, []);

  return {
    isOffline,
    places,
    balances,
    awardCosts,
    stampedPlaceId,
    setStampedPlaceId,
    awardedStampedPlaces,
    stampedPlace,
    onScanError,
  };
}
function useDashboardStyles({
  isOffline,
  state,
}: {
  isOffline: boolean;
  state: DashboardState;
}) {
  const {bottom} = useSafeAreaInsets();

  const stampControlVisible = true;
  const bottomMostSpacerHeight = useMemo(() => Math.max(bottom, 16), [bottom]);
  const paddedListStyle = useMemo(
    () => ({
      paddingHorizontal: Screen.PADDING_HORIZONTAL,
      paddingBottom:
        (isOffline
          ? NetworkErrorContentHeight + 16
          : stampControlVisible
          ? state.status === 'idle' || state.status === 'scanner'
            ? 138 + 16
            : 216 + 16
          : 0) + Math.max(bottom, 16),
    }),
    [bottom, stampControlVisible, state.status, isOffline],
  );

  const stampControlStyle = useMemo<ViewStyle>(
    () => ({
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: Math.max(bottom, 16),
      marginHorizontal: Screen.PADDING_HORIZONTAL,
    }),
    [bottom],
  );

  return {
    stampControlVisible,
    bottomMostSpacerHeight,
    paddedListStyle,
    stampControlStyle,
  };
}

type GetAwardedStampedPlacesProps = {
  balances: Balances;
  places: Place[];
  awardCosts: AwardCosts;
};
function getAwardedStampedPlaces({
  balances,
  places,
  awardCosts,
}: GetAwardedStampedPlacesProps) {
  return Object.keys(balances).reduce((memo, key: string) => {
    const {place_id: placeId, balance} = balances[Number(key)];
    const place = places.find(({id}) => id === placeId);
    // If awardCost from store doesn't exist try to get awardCost from place
    // to show placeDetails after scanning default QR
    const awardCost = awardCosts[placeId] || place?.stamp_count_for_reward;

    if (place && awardCost !== undefined) {
      memo.push({
        id: place.id,
        logo: place.logoThumb,
        name: place.name,
        stamps: balance,
        awards: Math.floor(balance / awardCost),
        awardCost: awardCost,
      });
    }

    return memo;
  }, [] as AwardedStampedPlace[]);
}

const styles = StyleSheet.create({
  screen: {
    ...StyleSheet.absoluteFillObject,
    paddingHorizontal: 0,
  },
  downloadAppBanner: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 44 + 16, // Navbar height + Spacer default height
    marginHorizontal: Screen.PADDING_HORIZONTAL,
  },
});

function Scanner({
  onBarCodeScanned,
  ...rest
}: ComponentProps<typeof QRCodeScanner> & ModalProps) {
  const {bottom} = useSafeAreaInsets();
  return (
    <Modal {...rest}>
      <QRCodeScanner
        onBarCodeScanned={onBarCodeScanned}
        onCancel={rest.onRequestClose as () => void}
        bottomGutter={Math.max(bottom, 16)}
      />
    </Modal>
  );
}
