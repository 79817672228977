import React, {useEffect, useMemo} from 'react';
import createStackNavigator from '../navigation/createStackNavigator';
import {RootNavigatorScreenParams} from '@navigation/config';
import {SignInScreen} from './SignIn';
import {DashboardScreen, DashboardGuest} from './Dashboard/Dashboard';
import DynamicLinks from '@utils/DynamicLinks';
import {getRouteFromDynamicLink} from '@utils/DynamicLinks/handleDynamicLink';
import {navigate} from '@navigation/navigationRef';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {LinkScreen} from '@screens/Link';
import {EmailSignInScreen} from '@screens/EmailSignIn';
import {NativeStackNavigationConfig} from 'react-native-screens/lib/typescript/native-stack/types';
import {isTablet} from 'react-native-device-info';
import {Navbar} from '@b2cmessenger/doppio-components';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {SettingsScreen} from '@screens/Settings';
import {AccountRemoval} from '@screens/AccountRemoval';
import {Platform} from 'react-native';

const Stack = createStackNavigator<RootNavigatorScreenParams>();
export const IS_ABLE_TO_REMOVE_ACCOUNT = Platform.OS !== 'web';

export function RootNavigator() {
  const {top} = useSafeAreaInsets();
  /**
   * 16 – default inset for `web` and devices with no "notch" or "hole"
   */
  const topInset = useMemo(() => Math.max(top, 16), [top]);
  const isRegistered = useIsRegistered();

  useEffect(() => {
    const unsubscribe = DynamicLinks.onLink((dl) => {
      const route = getRouteFromDynamicLink(dl);

      if (route) {
        navigate(route);
      }
    });
    return () => unsubscribe();
  }, []);

  return (
    <Navbar.TopInsetContext.Provider value={topInset}>
      <Stack.Navigator
        initialRouteName={isRegistered ? 'Dashboard' : 'DashboardGuest'}
        screenOptions={useMemo(
          () =>
            ({
              headerTopInsetEnabled: false,
              headerShown: false,
              screenOrientation: isTablet() ? undefined : 'portrait',
              title: 'Doppio',
            }) as NativeStackNavigationConfig,
          [],
        )}>
        {isRegistered ? (
          <>
            <Stack.Screen name="Dashboard" component={DashboardScreen} />
            <Stack.Screen name="Settings" component={SettingsScreen} />
            {IS_ABLE_TO_REMOVE_ACCOUNT ? (
              <Stack.Screen name="AccountRemoval" component={AccountRemoval} />
            ) : null}
          </>
        ) : (
          <>
            <Stack.Screen name="DashboardGuest" component={DashboardGuest} />
            <Stack.Screen name="SignIn" component={SignInScreen} />
            <Stack.Screen name="EmailSignIn" component={EmailSignInScreen} />
          </>
        )}
        <Stack.Screen name="Link" component={LinkScreen} />
      </Stack.Navigator>
    </Navbar.TopInsetContext.Provider>
  );
}

export function useIsRegistered() {
  const isLoggedIn = useSelector(loginedUserSelectors.isLoggedIn);
  const profile = useSelector(loginedUserSelectors.profile) as Exclude<
    ReturnType<typeof loginedUserSelectors.profile>,
    null
  >;
  const isRegistered = useMemo(
    () => isLoggedIn && !profile?.is_generated,
    [isLoggedIn, profile?.is_generated],
  );

  return isRegistered;
}
