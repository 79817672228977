import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  ActivityIndicator,
  Linking,
  Platform,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import {
  Screen,
  SocialSignInButton,
  Spacer,
  Typography,
  Pusher,
  Button,
  TextDivider,
} from '@b2cmessenger/doppio-components';
import {colors, Logger} from '@b2cmessenger/doppio-shared';
import {
  Analytics,
  apiMethods,
  PlaceAppearance,
} from '@b2cmessenger/doppio-core';
import {
  dismissLoginError,
  performLoginViaApple,
  performLoginViaFacebook,
  performLoginViaGoogle,
  setLoginParsedJwt,
} from '@store/actions';
import {useDispatch, useSelector} from 'react-redux';
import Config from '@utils/Config';
import useGoogleSignIn from '@components/hooks/auth/useGoogleSignIn';
import useFacebookSignIn from '@components/hooks/auth/useFacebookSignIn';
import useAppleSignIn from '@components/hooks/auth/useAppleSignIn';
import {TEST_IDS} from '@screens/SignInTestIDS';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {appSelectors, loginJwtSelectors} from '@store/selectors';
import {RootNavigatorScreenProps} from '@navigation/config';
import * as JWT from '@utils/JWT';
import {Alert} from '@components/common/Alert';
import {useIsFocused} from '@react-navigation/native';
import {IconDoppio} from '@components/common/icons/SvgIcon';
import DownloadAppBanner from '@screens/Dashboard/components/DownloadAppBanner';
import {buildDownloadAppLink} from '@utils/DynamicLinks/buildDownloadAppLink';
import FirebaseRemoteConfigContext from '@utils/FirebaseRemoteConfigContext';
// @ts-ignore
import BackgroundPatternSvg from '../../assets/background_pattern.svg';
import {checkAndUpdatePlaceAppearance} from '@utils/placeAppearance';
import usePlaceAppearance from '@components/hooks/usePlaceAppearance';
import {
  localization,
  useChangeLanguage,
  useGetCurrentLanguageName,
  useTranslation,
} from '@shared';
import {isTablet} from 'react-native-device-info';
import {navigationRef} from '@navigation/navigationRef';
import {useSetThemeColorMetaTag} from '@components/hooks/useSetThemeColorMetaTag';

useGoogleSignIn.configure(
  Config.GOOGLE_WEB_CLIENT_ID,
  Config.GOOGLE_IOS_CLIENT_ID,
);

export function SignInScreen({
  route,
  navigation,
}: RootNavigatorScreenProps<'SignIn'>) {
  useSetThemeColorMetaTag(colors.brand);

  const {displaySignInByEmail, displayDownloadAppBannerOnSignIn} = useContext(
    FirebaseRemoteConfigContext,
  );
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {bottom} = useSafeAreaInsets();

  const credential =
    route.params && route.params.jwt !== undefined
      ? route.params.jwt
      : undefined;

  useEffect(() => {
    if (credential) {
      const parsedJWT = JWT.parse(credential);
      if (parsedJWT && parsedJWT.type) {
        dispatch(setLoginParsedJwt(parsedJWT));
      } else {
        Logger.errorTag(
          'SignInScreen',
          'parsed jwt type is null, credential: ',
          credential,
        );
        dispatch(setLoginParsedJwt(null));
      }

      navigation.setParams({jwt: undefined});
    }
  }, [credential, dispatch, navigation]);

  const [captionText, setCaptionText] = useState<string | undefined>('');
  const parsedLoginJwt = useSelector(loginJwtSelectors.parsedJwt);
  useEffect(() => {
    if (parsedLoginJwt) {
      let isMounted = true;
      setCaptionText(undefined);

      const placeId = parsedLoginJwt.placeId;

      getStampCountForRewardByPlaceId(placeId)
        .catch(() => null)
        .then(async (stampCount) => {
          await checkAndUpdatePlaceAppearance(placeId);

          if (isMounted && stampCount) {
            const appearance = PlaceAppearance.storage.getAppearance(placeId);
            const askBusinessToClaim = t(
              'Screens.SignIn.askBusinessForTheQrCode',
            );
            const loyaltyInfo = appearance
              ? getLoyaltyInfoByStampCountAndAppearanceObject(
                  stampCount,
                  appearance,
                )
              : getLoyaltyInfoByStampCount(stampCount);
            const captionTxt = `${
              parsedLoginJwt.type === 'sb' ? askBusinessToClaim : ''
            }${loyaltyInfo}`;
            setCaptionText(captionTxt);
          }
        });
      return () => {
        isMounted = false;
      };
    } else {
      setCaptionText(t('Screens.SignIn.toCollectStamps') || '');
    }
  }, [parsedLoginJwt, t]);

  const {top} = useSafeAreaInsets();
  const isLoggingIn = useSelector(appSelectors.isLoggingIn);

  const {award: getAwardName, stamp: getStampName} = usePlaceAppearance(
    parsedLoginJwt?.placeId || 0,
  );

  const headerCaption = useMemo(() => {
    if (!parsedLoginJwt || parsedLoginJwt.type === null) {
      return '';
    }

    switch (parsedLoginJwt.type) {
      case 'sg':
        return t('Screens.SignIn.toGetStamps', {
          count: parsedLoginJwt.quantity,
          stampName: getStampName(parsedLoginJwt.quantity), // TODO not used, hardcoded in translation
        });
      case 'sa':
        return t('Screens.SignIn.toGetReward', {
          count: parsedLoginJwt.quantity,
          rewardName: getAwardName(parsedLoginJwt.quantity),
        });
      case 'sb':
        return t('Screens.SignIn.toCheckYourBalance');
    }
  }, [getAwardName, getStampName, parsedLoginJwt, t]);

  const headerText = useMemo(
    () =>
      [t('Screens.SignIn.SignIn'), headerCaption].filter(Boolean).join('\n'),
    [headerCaption, t],
  );

  const {currentLanguageName} = useGetCurrentLanguageName();
  const {changeLanguage} = useChangeLanguage({
    title: t('Screens.SignIn.chooseYourLanguage'),
  });

  // TODO undo after https://github.com/expo/router/issues/670 will be closed
  // useLayoutEffect(() => {
  //   if (Platform.OS === 'web') {
  //     navigation.setOptions({
  //       title: [
  //         t('Screens.SignIn.SignIn'),
  //         headerCaption || t('Screens.SignIn.inGetDoppio'),
  //       ].join(' '),
  //     });
  //   }
  // }, [headerCaption, navigation, t]);

  const onPressDownloadApp = useCallback(async () => {
    const url = buildDownloadAppLink();
    const supported = await Linking.canOpenURL(url);

    if (supported) {
      await Linking.openURL(url);
    } else {
      Alert.alert(
        t('AppBanner.ErrorOccurred'),
        t('AppBanner.tryLater') || undefined,
      );
    }
  }, [t]);

  const banner = useMemo(
    () =>
      Platform.OS === 'web' && displayDownloadAppBannerOnSignIn ? (
        <DownloadAppBanner
          onPress={onPressDownloadApp}
          style={{
            marginTop: top || 20,
          }}
        />
      ) : null,
    [displayDownloadAppBannerOnSignIn, onPressDownloadApp, top],
  );

  const backToDashboard = useMemo(() => {
    const _title = t('Screens.SignIn.secureStampsLater');
    return (
      <>
        <TextDivider
          text={t('Screens.SignIn.or') || 'or'}
          color={colors.white}
        />
        <Spacer />
        <Button.Inverted
          title={_title}
          // subtitle={_subtitle}
          subtitleStyle={{color: colors.darkgray}}
          onPress={() => navigationRef?.current?.navigate('DashboardGuest')}
        />
      </>
    );
  }, [t]);

  return (
    <Screen style={s.screen} testID={TEST_IDS.screen}>
      <Spacer height={top} />
      {banner}
      <Pusher />
      {Platform.OS !== 'web' ? (
        <View style={s.logoWrapper}>
          <IconDoppio />
          <Spacer height={32} />
        </View>
      ) : null}
      <Text style={s.header}>{headerText}</Text>
      <Spacer height={captionText === '' ? 0 : undefined} />
      {captionText === undefined ? (
        <ActivityIndicator
          size="small"
          style={s.captionLoader}
          color={colors.white}
        />
      ) : captionText ? (
        <Text style={s.caption}>{captionText}</Text>
      ) : null}
      <Spacer />
      <SignUpContainer
        loading={isLoggingIn}
        displaySignInByEmail={displaySignInByEmail}
      />

      <View style={s.languageContainer}>
        <Text style={[s.language]} onPress={changeLanguage}>
          {currentLanguageName}
        </Text>
      </View>

      <Pusher />
      {backToDashboard}
      <Spacer height={Math.max(bottom, 16)} />
    </Screen>
  );
}

function SignUpContainer({
  loading = false,
  displaySignInByEmail,
}: {
  loading?: boolean;
  displaySignInByEmail?: boolean;
}) {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const _isTablet = isTablet();

  const loginError = useSelector(appSelectors.loginError);
  const isFocused = useIsFocused();
  useEffect(() => {
    if (loginError && isFocused) {
      Logger.verboseTag('SignUpContainer [loginError]', loginError);
      Alert.alert(t('Screens.SignIn.Error'), loginError, [
        {
          text: t('Screens.SignIn.ok') || 'ok',
          style: 'cancel',
          onPress: dispatch.bind(null, dismissLoginError()),
        },
      ]);
    }
  }, [dispatch, isFocused, loginError, t]);

  const onAppleSignInSucceed = useCallback(
    (token, data) => {
      dispatch(performLoginViaApple(token, data));
    },
    [dispatch],
  );
  const onAppleSignInFailed = useCallback((error, raw) => {
    Logger.errorTag('SignIn with Apple', error, raw);
  }, []);
  const {
    isAvailable: isAppleSignInAvailable,
    loading: signingInApple,
    authorize: _signInApple,
  } = useAppleSignIn({
    onSuccess: onAppleSignInSucceed,
    onError: onAppleSignInFailed,
  });
  const signInApple = useCallback(() => {
    logLoginAttempt('apple');
    _signInApple();
  }, [_signInApple]);

  const onFacebookSignInSucceed = useCallback(
    (token, data) => {
      dispatch(performLoginViaFacebook(token, data));
    },
    [dispatch],
  );
  const onFacebookSignInFailed = useCallback((error) => {
    Logger.errorTag('SignIn with Facebook', error);
  }, []);
  const {loading: signingInFacebook, authorize: _signInFacebook} =
    useFacebookSignIn({
      webClientId: Config.FACEBOOK_APP_ID,
      onSuccess: onFacebookSignInSucceed,
      onError: onFacebookSignInFailed,
    });
  const signInFacebook = useCallback(() => {
    logLoginAttempt('facebook');
    _signInFacebook();
  }, [_signInFacebook]);

  const onGoogleSignInSucceed = useCallback(
    (serverAuthCode: string) => {
      dispatch(performLoginViaGoogle(serverAuthCode));
    },
    [dispatch],
  );
  const onGoogleSignInFailed = useCallback((error, more) => {
    Logger.errorTag('SignIn with Google', error, more);
  }, []);
  const {loading: signingInGoogle, authorize: _signInGoogle} = useGoogleSignIn({
    clientId: Config.GOOGLE_WEB_CLIENT_ID,
    onSuccess: onGoogleSignInSucceed,
    onError: onGoogleSignInFailed,
  });
  const signInGoogle = useCallback(() => {
    logLoginAttempt('google');
    _signInGoogle();
  }, [_signInGoogle]);

  const onPressSignInByEmail = useCallback(() => {
    logLoginAttempt('email');
    navigationRef?.current?.navigate('EmailSignIn');
  }, []);

  const buttonStyle = useMemo(
    () => (loading ? {opacity: 0} : undefined),
    [loading],
  );

  const signInWithApple = t('Screens.SignIn.signInWithApple');
  const signInWithFacebook = t('Screens.SignIn.signInWithFacebook');
  const signInWithGoogle = t('Screens.SignIn.signInWithGoogle');
  const signInWithEmail = t('Screens.SignIn.signInWithEmail');

  const languageCode = localization.getLanguage();
  const buttonStyles = useMemo(() => {
    return _isTablet || languageCode === 'en' ? undefined : {width: 265};
  }, [_isTablet, languageCode]);

  return (
    <View style={s.signUp} pointerEvents={loading ? 'none' : undefined}>
      <View style={buttonStyle}>
        {isAppleSignInAvailable ? (
          <>
            <SocialSignInButton.Apple
              testID={TEST_IDS.buttons.Apple}
              disabled={signingInApple}
              onPress={signInApple}
              title={signInWithApple}
              style={buttonStyles}
            />
            <Spacer />
          </>
        ) : null}
        <SocialSignInButton.Facebook
          testID={TEST_IDS.buttons.Facebook}
          disabled={signingInFacebook}
          onPress={signInFacebook}
          title={signInWithFacebook}
          style={buttonStyles}
        />
        <Spacer />
        <SocialSignInButton.Google
          testID={TEST_IDS.buttons.Google}
          disabled={signingInGoogle}
          onPress={signInGoogle}
          title={signInWithGoogle}
          style={buttonStyles}
        />
        {displaySignInByEmail ? (
          <>
            <Spacer />
            <SocialSignInButton.Email
              onPress={onPressSignInByEmail}
              disabled={loading}
              title={signInWithEmail}
              style={buttonStyles}
            />
          </>
        ) : null}
      </View>
      {loading ? (
        <View style={StyleSheet.absoluteFill}>
          <Spacer />
          <ActivityIndicator size="large" color={colors.white} />
          <Spacer />
        </View>
      ) : null}
    </View>
  );
}

function logLoginAttempt(channel: 'email' | 'apple' | 'google' | 'facebook') {
  Analytics.logEvent('login_attempt', {channel});
}

const s = StyleSheet.create({
  screen: Platform.select({
    web: {
      backgroundColor: colors.brand,
      'background-image': `url(${BackgroundPatternSvg})`,
      'background-size': 'cover',
    },
    default: {
      backgroundColor: colors.brand,
    },
  }),
  logoWrapper: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    ...StyleSheet.flatten(Typography.header),
    color: colors.white,
    textAlign: 'center',
  },
  caption: {
    ...StyleSheet.flatten(Typography.mediumBody),
    color: colors.white,
    textAlign: 'center',
  },
  captionLoader: {
    height: StyleSheet.flatten(Typography.mediumBody).lineHeight,
  },
  signUp: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  languageContainer: {
    flexDirection: 'row',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignItems: 'center',
    marginTop: 16,
  },
  language: {
    color: colors.lightgray,
    textDecorationLine: 'underline',
  },
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const [DEFAULT_AWARD_ID, DEFAULT_AWARD_NAME] = [0 as const, 'bonus' as const];

async function getStampCountForRewardByPlaceId(placeId: number) {
  const place = await apiMethods.getPlace({id: placeId});
  if (place) {
    return place.stamp_count_for_reward;
  }

  return null;
}

function getLoyaltyInfoByStampsAndRewardName(stamps: number, reward: string) {
  return localization.t('Screens.SignIn.itsSimple', {
    count: stamps,
    reward: reward,
  });
}

function getLoyaltyInfoByStampCount(stamps: number) {
  return getLoyaltyInfoByStampsAndRewardName(stamps, DEFAULT_AWARD_NAME);
}

type PlaceAppearanceStorageObject = {
  stamp: (qty: number) => string;
  award: (qty: number) => string;
};

function getLoyaltyInfoByStampCountAndAppearanceObject(
  stamps: number,
  appearance: PlaceAppearanceStorageObject,
) {
  return getLoyaltyInfoByStampsAndRewardName(stamps, appearance.award(1));
}
