import {
  createDraftSafeSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import {slices} from '@b2cmessenger/doppio-core';
import type {UserStampBalances} from '@typings/ApiSpec';
import {keyBy, memoize} from 'lodash';

type SinglePlaceBalance = UserStampBalances[0];
export type Balances = Record<
  SinglePlaceBalance['place_id'],
  SinglePlaceBalance
>;
type State = {
  balances: Balances;
};

const initialState: State = {
  balances: {},
};

const {reducer, actions} = createSlice({
  name: 'stamps',
  initialState: initialState,
  reducers: {
    updateBalances(
      state,
      action: PayloadAction<{userStampBalances: UserStampBalances}>,
    ) {
      state.balances = keyBy(action.payload.userStampBalances, 'place_id');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(slices.shared.actions.logout, () => initialState)
      .addCase(
        slices.shared.actions.applyStampOperationResult,
        (state, action) => {
          const placeId = action.payload.place.id;

          state.balances[placeId] = {
            ...(state.balances[placeId] || {
              place_id: placeId,
              updated_at: action.payload.updated_at,
            }),
            balance: action.payload.balance,
          };
        },
      )
      .addMatcher(slices.shared.matchers.isInitLikeAction, (state, action) => {
        if ('userStampBalances' in action.payload) {
          state.balances = keyBy(action.payload.userStampBalances, 'place_id');
        }
      });
  },
});

const selectSelf = (state: ReturnType<typeof reducer>) => state;
const balancesMapSelector = createDraftSafeSelector(
  selectSelf,
  ({balances}) => balances,
);
const placeBalanceSelector = createDraftSafeSelector(selectSelf, (state) =>
  memoize((placeId: number) => state.balances[placeId] || undefined),
);

const selectors = {
  balancesMap: balancesMapSelector,
  placeBalance: placeBalanceSelector,
};

export {reducer, actions, selectors};
