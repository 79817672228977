import {slices, createAbsoluteSelectors} from '@b2cmessenger/doppio-core';
import {selectors as stamps} from './slices/stamps';
import {selectors as userPlaces} from './slices/userPlaces';
import {selectors as loginJwt} from './loginJwt.slice';
import {selectors as appTracking} from './slices/appTracking';

import {parentSelectors} from '@store/reducer';

export const appSelectors = createAbsoluteSelectors(
  parentSelectors.app,
  slices.app.selectors,
);
export const initSelectors = createAbsoluteSelectors(
  parentSelectors.init,
  slices.init.selectors,
);
export const loginedUserSelectors = createAbsoluteSelectors(
  parentSelectors.loginedUser,
  slices.loginedUser.selectors,
);
export const stampsSelectors = createAbsoluteSelectors(
  parentSelectors.stamps,
  stamps,
);
export const stampAwardsSelectors = createAbsoluteSelectors(
  parentSelectors.stampAwards,
  slices.stampAwards.selectors,
);
export const userPlacesSelectors = createAbsoluteSelectors(
  parentSelectors.userPlaces,
  userPlaces,
);
export const loginJwtSelectors = createAbsoluteSelectors(
  parentSelectors.loginJwt,
  loginJwt,
);
export const appTrackingSelectors = createAbsoluteSelectors(
  parentSelectors.appTracking,
  appTracking,
);
export const networkSelectors = createAbsoluteSelectors(
  parentSelectors.network,
  slices.network.selectors,
);
