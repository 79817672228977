import {useCallback} from 'react';
import {Alert} from '@components/common/Alert';
import {logout} from '@store/actions';
import {useDispatch} from 'react-redux';
import {useTranslation} from '@shared';

export function useLogoutPrompt() {
  const {t} = useTranslation();

  const dispatch = useDispatch();
  return useCallback(() => {
    Alert.alert(
      t('logoutPrompt.title'),
      t('logoutPrompt.message') || undefined,
      [
        {text: t('logoutPrompt.buttonCancel') || undefined, style: 'cancel'},
        {
          text: t('logoutPrompt.buttonOk') || undefined,
          style: 'destructive',
          onPress: dispatch.bind(null, logout()),
        },
      ],
    );
  }, [dispatch, t]);
}
