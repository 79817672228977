import React, {PropsWithoutRef, useCallback, useMemo} from 'react';
import {
  Animated,
  Platform,
  SectionList,
  SectionListData,
  SectionListProps,
  StyleSheet,
  useWindowDimensions,
  View,
} from 'react-native';
import {
  Button,
  Card,
  Screen,
  Spacer,
  ThemeProvider,
  Typography,
} from '@b2cmessenger/doppio-components';
import {colors, CardStyleHelpers} from '@b2cmessenger/doppio-shared';
import {Text} from 'react-native-paper';
import {StateLine} from '@screens/Dashboard/components/StampControl';
import {AwardedStampedPlace, ControlState} from '../shared';
import {StampListRow} from '@components/common/StampList';
import {PlaceLogo} from '@screens/Dashboard/components/PlaceLogo';
import {isTablet} from 'react-native-device-info';
import usePlaceAppearance from '@components/hooks/usePlaceAppearance';
import {getStampSections} from '@screens/Dashboard/components/shared';
import {useTranslation} from '@shared';
const _isTablet = isTablet();

const STAMP_PER_ROW = 5;
type PlaceAwardsDetailsProps = PropsWithoutRef<
  Omit<SectionListProps<any>, 'sections'>
> & {
  place: AwardedStampedPlace;
  awardCost: number;
  state?: ControlState;
  placeLogoSize?: number;
};
function PlaceAwardsDetails(props: PlaceAwardsDetailsProps) {
  const appearance = usePlaceAppearance(props.place.id);
  return <PlaceAwardsDetailsContent {...props} appearance={appearance} />;
}

export function PlaceAwardsDetailsContent(
  props: PlaceAwardsDetailsProps & {
    appearance: ReturnType<typeof usePlaceAppearance>;
  },
) {
  const {t} = useTranslation();
  const {
    place,
    awardCost,
    state: controlState,
    placeLogoSize = 44,
    appearance,
    ...rest
  } = props;

  const {
    award: getAwardName,
    stamp: getStampName,
    IconStamp,
    IconAward,
    color: brandColor,
  } = appearance;

  const cardStyle = CardStyleHelpers.useCardStyle({
    shadowEnabled: true, //Platform.OS !== 'android', why?
  });
  const {width} = useWindowDimensions();
  const stampSize = useMemo(
    () =>
      Math.min(
        70,
        (width -
          (Screen.PADDING_HORIZONTAL * 2 + Card.PADDING_HORIZONTAL * 2)) /
          STAMP_PER_ROW,
      ),
    [width],
  );
  const {logo, name, awards, stamps} = place;
  const askBusinessToClimStamps = useMemo(
    () =>
      awards === 0 && stamps === 0
        ? t('Screens.Dashboard.Place.askBusinessForTheQrCode')
        : '',
    [awards, stamps, t],
  );

  const heading = useMemo(
    () =>
      controlState?.status === 'stamp' &&
      controlState?.quantity === stamps &&
      stamps > 0
        ? t('Screens.Dashboard.Place.youGotFirstStamp')
        : name,
    [controlState, name, stamps, t],
  );

  const renderSectionHeader = useCallback(
    ({section}) =>
      section.name === 'stamps' ? (
        <ThemeProvider colors={{brand: brandColor}}>
          <Card
            shadowEnabled={false}
            paddingHorizontal={Card.PADDING_HORIZONTAL}
            style={{marginHorizontal: -Card.PADDING_HORIZONTAL}}>
            <PlaceLogo
              uri={logo}
              size={placeLogoSize as number}
              style={styles.logo}
              fill={brandColor}
            />
            <Spacer height={8} />
            <Text style={Typography.header}>{heading}</Text>
            <Spacer height={8} />
            <Text style={Typography.body}>
              {askBusinessToClimStamps}
              {t('Screens.Dashboard.Place.itsSimple', {
                count: awardCost,
                reward: getAwardName(1),
              })}
            </Text>
            <Spacer height={8} />

            <View style={styles.buttonsWrapper} pointerEvents="none">
              <View style={[styles.buttonWrapper, {marginRight: 8}]}>
                <Button.ExtraSmall
                  style={styles.button}
                  titleStyle={styles.buttonTitle}
                  mode="secondary"
                  title={t('Screens.Dashboard.Place.rewards', {
                    count: awards,
                    rewardName: getAwardName(awards),
                  })}
                />
              </View>
              <View style={styles.buttonWrapper} pointerEvents="none">
                <Button.ExtraSmall
                  style={styles.button}
                  titleStyle={styles.buttonTitle}
                  title={t('Screens.Dashboard.Place.stamps', {
                    count: stamps,
                  })}
                />
              </View>
            </View>
          </Card>
        </ThemeProvider>
      ) : null,
    [
      askBusinessToClimStamps,
      awardCost,
      awards,
      brandColor,
      getAwardName,
      heading,
      logo,
      placeLogoSize,
      stamps,
      t,
    ],
  );

  const contentContainerStyle = useMemo(
    () =>
      Platform.OS === 'web' || _isTablet
        ? {
            maxWidth:
              stampSize * STAMP_PER_ROW +
              Card.PADDING_HORIZONTAL * 2 +
              Screen.PADDING_HORIZONTAL * 2,
          }
        : undefined,
    [stampSize],
  );

  const sections = useMemo(() => {
    return [
      {
        name: 'stamps',
        data: [
          controlState ? 'state' : undefined,
          ...getStampSections(stamps, awardCost, STAMP_PER_ROW),
        ].filter((e) => e !== undefined),
        keyExtractor: (el: number[] | string) => {
          return typeof el === 'string' ? el : `${el[1]}`;
        },
        renderItem: ({item}) =>
          item === 'state' && controlState ? (
            <View style={ss.topMostCardWrapper}>
              <Card
                shadowEnabled={false}
                paddingVertical={10}
                backgroundColor={
                  controlState.status === 'award'
                    ? colors.black
                    : controlState.status === 'stamp'
                    ? brandColor
                    : colors.red
                }
                style={StyleSheet.absoluteFill}>
                <StateLine
                  value={controlState}
                  IconStamp={IconStamp}
                  IconAward={IconAward}
                  getAwardName={getAwardName}
                  getStampName={getStampName}
                />
              </Card>
              <Spacer height={64} />
            </View>
          ) : (
            <View style={ss.shadowFixerWrapper}>
              <Animated.View
                style={{
                  ...cardStyle,
                  ...(() => {
                    switch (item[2]) {
                      case 'first':
                        return {
                          paddingTop: cardStyle.paddingVertical,
                          paddingBottom: 0,
                          borderBottomLeftRadius: 0,
                          borderBottomRightRadius: 0,
                        };
                      case 'last':
                        return {
                          paddingTop: 0,
                          paddingBottom: cardStyle.paddingVertical,
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                        };
                      case 'single':
                        return {};
                      case 'row':
                      default:
                        return {
                          paddingVertical: 0,
                          borderRadius: 0,
                        };
                    }
                  })(),
                }}>
                <StampListRow
                  stampSize={stampSize}
                  value={item[0] as number}
                  stampPerRow={STAMP_PER_ROW}
                  IconStamp={IconStamp}
                />
              </Animated.View>
              {item[2] === 'first' || 'single' ? null : (
                <View style={ss.topShadowFixer} />
              )}
            </View>
          ),
      } as SectionListData<number[] | string>,
    ];
  }, [
    IconAward,
    IconStamp,
    awardCost,
    brandColor,
    cardStyle,
    controlState,
    getAwardName,
    getStampName,
    stampSize,
    stamps,
  ]);

  return (
    <SectionList
      {...rest}
      keyExtractor={useCallback(() => '1', [])}
      stickySectionHeadersEnabled
      renderSectionHeader={renderSectionHeader}
      contentContainerStyle={[
        contentContainerStyle,
        rest.contentContainerStyle,
      ]}
      sections={sections}
    />
  );
}

const styles = StyleSheet.create({
  logo: {
    width: 44,
    height: 44,
    borderRadius: 6,
  },
  buttonWrapper: {
    alignSelf: 'flex-start',
  },
  buttonsWrapper: {
    flexDirection: 'row',
  },
  button: {
    borderRadius: 14,
  },
  buttonTitle: {
    textAlign: 'left',
  },
});

const ss = StyleSheet.create({
  topMostCardWrapper: {
    overflow: 'hidden',
    marginBottom: -30,
    paddingBottom: 30,
  },
  shadowFixerWrapper: {
    margin: -10,
    padding: 10,
    overflow: 'hidden',
    /**
     * This fixes iOS renders shadow over previous row
     */
    ...Platform.select({
      ios: {
        marginTop: -1,
        paddingTop: 0,
      },
      default: {},
    }),
  },
  topShadowFixer: {
    position: 'absolute',
    backgroundColor: 'white',
    left: 10,
    right: 10,
    top: 5,
    height: 10,
  },
});

export default PlaceAwardsDetails;
