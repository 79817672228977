import React, {ComponentProps, useMemo} from 'react';
import {
  Button,
  Card,
  Spacer,
  ThemeProvider,
  Typography,
} from '@b2cmessenger/doppio-components';
import {colors} from '@b2cmessenger/doppio-shared';
import {
  ActivityIndicator,
  Animated,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native';
import {IconQR, IconStampControl} from '@components/common/icons/SvgIcon';
import {ControlState} from '@screens/Dashboard/shared';
import usePlaceAppearance from '@components/hooks/usePlaceAppearance';
import {IconAwardType, IconStampType} from '@typings/shared';
import {useSelector} from 'react-redux';
import {stampAwardsSelectors, stampsSelectors} from '@store/selectors';
import {localization, useTranslation} from '@shared';

type ControlStateWithBalanceAndAwardCost = ControlState & {
  placeBalance?: number;
  awardCost?: number;
};

export function StampControl({
  loading,
  state,
  onStateReset,
  onScanQR,
  style: _style,
  appearancePlaceId,
}: {
  loading?: boolean;
  state?: ControlState;
  onStateReset?: () => void;
  onScanQR?: () => void;
  style?: ComponentProps<typeof Animated.View>['style'];
  appearancePlaceId?: number;
}) {
  const status = state?.status;
  const {
    award: getAwardName,
    stamp: getStampName,
    IconAward,
    IconStamp,
    color: brandColor,
  } = usePlaceAppearance(state?.placeId || appearancePlaceId || 0);

  const backgroundColor = useMemo(
    () =>
      status === 'award'
        ? colors.black
        : status === 'stamp'
        ? brandColor
        : status === 'awardError' || status === 'stampError'
        ? colors.red
        : brandColor,
    [brandColor, status],
  );
  const cardStyle = useMemo(() => [styles.card, _style], [_style]);
  const cardContentStyle = useMemo(
    () => [
      styles.cardContent,
      {
        backgroundColor: backgroundColor,
        ...(status !== undefined && status !== 'loading' ? {height: 185} : {}),
      },
    ],
    [backgroundColor, status],
  );
  return (
    <Card
      backgroundColor={backgroundColor}
      paddingVertical={0}
      shadowColor={backgroundColor}
      style={cardStyle}>
      <View style={cardContentStyle}>
        {loading ? (
          <ActivityIndicator
            style={StyleSheet.absoluteFill}
            color={colors.white}
            size="large"
          />
        ) : state === undefined ? (
          <ScanPlaceholder onPress={onScanQR} />
        ) : (
          <ThemeProvider colors={{brand: brandColor}}>
            <StateBlock
              value={state}
              getAwardName={getAwardName}
              getStampName={getStampName}
              IconAward={IconAward}
              IconStamp={IconStamp}
              onButtonPress={onStateReset}
            />
          </ThemeProvider>
        )}
      </View>
    </Card>
  );
}

const styles = StyleSheet.create({
  card: {},
  cardContent: {
    justifyContent: 'center',
    backgroundColor: colors.brand,
    height: 120,
  },
});

function StateBlock({
  value,
  onButtonPress,
  IconStamp,
  IconAward,
  getAwardName,
  getStampName,
}: {
  value: ControlState;
  IconStamp: IconStampType;
  IconAward: IconAwardType;
  onButtonPress?: () => void;
  getAwardName: (qty: number) => string;
  getStampName: (qty: number) => string;
}) {
  const {t} = useTranslation();

  const placeBalance = useStampBalance(value.placeId);
  const awardCost = useAwardCost(value.placeId);

  const title = useMemo(
    () =>
      getStateBlockTitle({
        state: {...value, placeBalance, awardCost},
        getAwardName,
        getStampName,
      }),
    [awardCost, getAwardName, getStampName, placeBalance, value],
  );
  const caption = useMemo(
    () =>
      getStateBlockCaption({
        state: {...value, placeBalance, awardCost},
        getStampName,
        getAwardName,
      }),
    [awardCost, getAwardName, getStampName, placeBalance, value],
  );

  return (
    <Animated.View style={sbStyles.container}>
      <StateBlockIcon
        status={value.status}
        size={44}
        IconStamp={IconStamp}
        IconAward={IconAward}
      />
      <Spacer height={6} />
      {title ? (
        <Text style={sbStyles.button} numberOfLines={1}>
          {title}
        </Text>
      ) : null}
      <Spacer height={6} />
      {caption ? (
        <Text style={sbStyles.caption} numberOfLines={2}>
          {caption}
        </Text>
      ) : null}
      <Spacer height={16} />
      <Button.Inverted
        style={sbStyles.doneButton}
        onPress={onButtonPress}
        title={
          value.status === 'award' || value.status === 'stamp'
            ? t('Screens.Dashboard.StampControl.okButton_success')
            : t('Screens.Dashboard.StampControl.okButton_error')
        }
      />
    </Animated.View>
  );
}

function getStateBlockTitle({
  state,
  getStampName,
  getAwardName,
}: {
  state: ControlStateWithBalanceAndAwardCost;
  getAwardName: (qty: number) => string;
  getStampName: (qty: number) => string;
}) {
  switch (state.status) {
    case 'award':
      return localization.t('Screens.Dashboard.StampControl.award.blockTitle', {
        count: state.quantity,
        rewardName: getAwardName(state.quantity),
      });
    case 'stamp':
      return localization.t('Screens.Dashboard.StampControl.stamp.blockTitle', {
        count: state.quantity,
        stampName: getStampName(state.quantity),
      });
    case 'stampError':
      return `${state.reason}`;
    case 'awardError':
      return localization.t(
        'Screens.Dashboard.StampControl.awardError.blockTitle',
        {
          count: state.remains,
          stampName: getStampName(state.remains),
        },
      );
    default:
      return undefined;
  }
}

function getStateBlockCaption({
  state,
  getStampName,
  getAwardName,
}: {
  state: ControlStateWithBalanceAndAwardCost;
  getStampName: (qty: number) => string;
  getAwardName: (qty: number) => string;
}) {
  switch (state.status) {
    case 'award':
      return localization.t(
        'Screens.Dashboard.StampControl.award.blockCaption',
        {
          placeName: state.placeName,
        },
      );
    case 'stamp':
      if (state.placeBalance && state.awardCost) {
        const rest = state.placeBalance % state.awardCost;
        if (rest === 0) {
          return localization.t(
            'Screens.Dashboard.StampControl.stamp.blockCaption_stampsEnough',
            {stampName: getStampName(2), rewardName: getAwardName(1)},
          );
        }
        const stampsToAward = state.awardCost - rest;
        if (stampsToAward <= Math.ceil(state.awardCost / 2)) {
          return localization.t(
            'Screens.Dashboard.StampControl.stamp.blockCaption_moreStampsToGrab',
            {
              count: stampsToAward,
              stampName: getStampName(stampsToAward),
              rewardName: getAwardName(1),
            },
          );
        }
      }
      return localization.t(
        'Screens.Dashboard.StampControl.stamp.blockCaption',
        {
          placeName: state.placeName,
        },
      );
    case 'awardError':
      if (state.awardName) {
        return localization.t(
          'Screens.Dashboard.StampControl.awardError.blockCaption_awardName',
          {
            count: state.awardQuantity,
            rewardName: getAwardName(state.awardQuantity),
            placeName: state.placeName,
          },
        );
      }
      if (state.placeName) {
        return localization.t(
          'Screens.Dashboard.StampControl.awardError.blockCaption',
          {
            placeName: state.placeName,
          },
        );
      }

      return undefined;
    case 'stampError':
      return state.placeName
        ? localization.t(
            'Screens.Dashboard.StampControl.stampError.blockCaption',
            {
              placeName: state.placeName,
            },
          )
        : undefined;
    default:
      return undefined;
  }
}

const sbStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  button: {
    ...StyleSheet.flatten(Typography.button),
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.white,
  },
  caption: {
    ...StyleSheet.flatten(Typography.extraSmallButton),
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.white,
  },
  doneButton: {
    width: '100%',
  },
});

export function StateLine({
  value,
  getAwardName,
  getStampName,
  IconStamp,
  IconAward,
}: {
  value: ControlState;
  IconStamp: IconStampType;
  IconAward: IconAwardType;
  getAwardName: (qty: number) => string;
  getStampName: (qty: number) => string;
}) {
  const title = useMemo(
    () => getStateLineTitle({state: value, getStampName, getAwardName}),
    [value, getAwardName, getStampName],
  );
  return (
    <View style={slStyles.container}>
      <StateBlockIcon
        status={value.status}
        size={44}
        IconStamp={IconStamp}
        IconAward={IconAward}
      />
      {title ? (
        <Text style={slStyles.title} numberOfLines={1}>
          {title}
        </Text>
      ) : null}
    </View>
  );
}

function getStateLineTitle({
  state,
  getAwardName,
  getStampName,
}: {
  state: ControlState;
  getAwardName: (qty: number) => string;
  getStampName: (qty: number) => string;
}) {
  switch (state.status) {
    case 'award':
      return localization.t('Screens.Dashboard.StampControl.award.stateLine', {
        count: state.quantity,
        rewardName: getAwardName(state.quantity),
      });
    case 'stamp':
      return localization.t('Screens.Dashboard.StampControl.stamp.stateLine', {
        count: state.quantity,
        stampName: getStampName(state.quantity),
      });
    case 'awardError':
      return localization.t(
        'Screens.Dashboard.StampControl.awardError.stateLine',
        {
          count: state.remains,
          stampName: getStampName(state.remains),
        },
      );
    case 'stampError':
      return `${state.reason}`;
    default:
      return undefined;
  }
}

const slStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    ...StyleSheet.flatten(Typography.smallHeader),
    color: colors.white,
    marginLeft: 16,
  },
});

function StateBlockIcon({
  status,
  size = 44,
  IconStamp,
  IconAward,
}: {
  status: ControlState['status'];
  size?: number;
  IconStamp: IconStampType;
  IconAward: IconAwardType;
}) {
  return useMemo(() => {
    switch (status) {
      case 'award':
        return <IconAward size={size} />;
      case 'stamp':
        return <IconStamp size={size} active={true} />;
      case 'awardError':
      case 'stampError':
        return <IconStampControl size={size} type={'error'} />;
      default:
        return null;
    }
  }, [size, status]);
}

function ScanPlaceholder({onPress}: Pick<TouchableOpacityProps, 'onPress'>) {
  const {t} = useTranslation();

  return (
    <TouchableOpacity style={spStyles.container} onPress={onPress}>
      <IconQR size={28} />
      <Spacer height={8} />
      <Text style={spStyles.button}>
        {t('Screens.Dashboard.StampControl.scanQr')}
      </Text>
    </TouchableOpacity>
  );
}

function useStampBalance(placeId?: number) {
  const balances = useSelector(stampsSelectors.placeBalance);

  return useMemo(() => {
    if (!placeId) {
      return undefined;
    }

    return balances(placeId)?.balance;
  }, [balances, placeId]);
}

function useAwardCost(placeId?: number) {
  const costs = useSelector(stampAwardsSelectors.placeAwardCost);

  return useMemo(() => {
    if (!placeId) {
      return undefined;
    }

    return costs(placeId, 0);
  }, [costs, placeId]);
}

const spStyles = StyleSheet.create({
  container: {
    alignItems: 'center',
  },
  button: {
    ...StyleSheet.flatten(Typography.button),
    textAlign: 'center',
    textTransform: 'uppercase',
    color: colors.white,
  },
  caption: {
    fontSize: 10,
    lineHeight: 12,
    fontWeight: '500',
    textAlign: 'center',
    color: colors.white,
  },
});
