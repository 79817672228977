import {Logger} from '@b2cmessenger/doppio-shared';
import {FirebaseDynamicLinksTypes} from '@react-native-firebase/dynamic-links';
import * as JWT from '../JWT';
import {RootNavigatorScreenParams} from '@navigation/config';
import {DynamicLinksConfig} from '@utils/DynamicLinks/shared';
import {RouteProp} from '@react-navigation/native';
import {URLSearchParams} from 'react-native-url-polyfill';
import DynamicLinks from '@utils/DynamicLinks';
import NetworkError from '@utils/Errors/NetworkError';

export type DynamicLink = Pick<FirebaseDynamicLinksTypes.DynamicLink, 'url'>;
export enum DynamicLinkStampType {
  StampAward = 'stamp_award',
  RedeemFreeGift = 'redeem_free_gift',
  StampBalance = 'stamp_balance',
}

const DynamicLinkTagMap = {
  sa: DynamicLinkStampType.StampAward,
  sb: DynamicLinkStampType.StampBalance,
  sg: DynamicLinkStampType.RedeemFreeGift,
};

type HandledDynamicLinkStamp = Omit<JWT.JwtParseResult, 'type'> & {
  type: DynamicLinkStampType;
};

enum DynamicLinkAuthType {
  Auth = 'auth',
}
type HandledDynamicLinkAuth = {
  type: DynamicLinkAuthType;
  accessToken: string;
};

type HandledDynamicLink = HandledDynamicLinkStamp | HandledDynamicLinkAuth;
export function isHandledDynamicLinkStampResult(
  result: HandledDynamicLink,
): result is HandledDynamicLinkStamp {
  return (
    result &&
    result.type &&
    Object.values(DynamicLinkStampType).includes(
      (result as HandledDynamicLinkStamp).type,
    )
  );
}

export function isHandledDynamicLinkAuthResult(
  result: HandledDynamicLink,
): result is HandledDynamicLinkAuth {
  return (
    result &&
    result.type &&
    Object.values(DynamicLinkAuthType).includes(
      (result as HandledDynamicLinkAuth).type,
    )
  );
}

export function handleDynamicLink(dl: Pick<DynamicLink, 'url'>) {
  const {path} = parseUrlFromDynamicLink(dl.url);

  if (path) {
    if (path.startsWith('/link')) {
      const [jwt] = path.substr('/link'.length).split('/').filter(Boolean);
      const parsed = JWT.parse(jwt);
      if (parsed.type !== null) {
        const {type, ...payload} = parsed;
        return {
          type: DynamicLinkTagMap[type],
          ...payload,
        };
      }
    } else if (path.startsWith('/auth')) {
      const [accessToken] = path
        .substr('/auth'.length)
        .split('/')
        .filter(Boolean);

      Logger.infoTag(
        'handleDynamicLink',
        'Parsed access token from DynamicLink',
      );

      return {type: DynamicLinkAuthType.Auth, accessToken};
    }
  }

  Logger.infoTag('handleDynamicLink', 'Unrecognized dynamic link');

  return null;
}

export function getRouteFromDynamicLink(dl: DynamicLink) {
  const result = handleDynamicLink(dl);
  if (result) {
    Logger.verboseTag('getRouteFromDynamicLink', `found ${result.type} link`);
    if (isHandledDynamicLinkStampResult(result)) {
      return {
        name: 'Link' as keyof RootNavigatorScreenParams,
        params: {
          jwt: result.jwt,
        },
      } as RouteProp<RootNavigatorScreenParams, 'Link'>;
    } else if (isHandledDynamicLinkAuthResult(result)) {
      return {
        name: 'Link',
        params: {
          accessToken: result.accessToken,
        },
      } as RouteProp<RootNavigatorScreenParams, 'Link'>;
    } else {
      Logger.warnTag(
        'getRouteFromDynamicLink',
        'Got dynamic link with unknown type',
      );
    }
  }

  return null;
}

function parseLink(url: string, startsWith: string) {
  if (url.startsWith(startsWith)) {
    let urlWithoutDomain = url.substr(startsWith.length);
    if (!urlWithoutDomain.startsWith('/')) {
      urlWithoutDomain = '/' + urlWithoutDomain;
    }
    const [path, _params] = urlWithoutDomain.split('?');
    const params = new URLSearchParams(_params);
    return {
      url,
      path,
      params,
    };
  }

  return {
    url,
    path: undefined,
    params: {},
  };
}

export function parseUrlFromDynamicLink(url: string) {
  const DynamicLinkDomain = DynamicLinksConfig.Client.DynamicLinkDomain;
  return parseLink(url, DynamicLinkDomain);
}

export function parseDynamicLink(link: string) {
  const DynamicLinkDomainURIPrefix = DynamicLinksConfig.Client.DomainURIPrefix;
  return parseLink(link, DynamicLinkDomainURIPrefix);
}

/**
 * DynamicLinks.resolveLink throws error with `not-found` code in cases:
 * 1) Invalid DynamicLink
 * 2) Network unavailable
 * So in case of error we can check link manually for the correct primary signs
 * And if it is correct treat error as network issue
 *
 * @param link string
 * @throws NetworkError
 * @throws Error
 */
export async function resolveLink(link: string): Promise<DynamicLink> {
  try {
    return await DynamicLinks.resolveLink(link);
  } catch (e) {
    // Correct DL must contain correct domain prefix and has `link` param
    const parsedDl = parseDynamicLink(link);
    if (parsedDl.path && parsedDl.params.has('link')) {
      throw new NetworkError(
        'Internet connection is required to resolve Dynamic link',
      );
    } else {
      throw e;
    }
  }
}
