import {Place, PlaceAppearanceType} from '@typings/ApiSpec';
import {api} from '@b2cmessenger/doppio-core';
import {AxiosError} from 'axios';
import {Logger} from '@b2cmessenger/doppio-shared';

export async function getPlaceAppearance(placeId: Place['id']) {
  return api.default
    .get<PlaceAppearanceType>(`/api/v2/place/${String(placeId)}/appearance`)
    .then(api.getResponseData)
    .catch((e: AxiosError) => {
      Logger.errorTag('getPlaceAppearance', e);
      return undefined;
    });
}
