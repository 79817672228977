/**
 * PunchIcon icon set component.
 * Usage: <PunchIcon name="icon-name" size={20} color="#4F8EF7" />
 */

import {createIconSet} from 'react-native-vector-icons';

/* eslint-disable */
export const glyphMap = {
  "drop": 59651,
  "apple": 59652,
  "profile": 59653,
  "empl": 59654,
  "coffee": 59655,
  "back": 59656,
  "set": 59657,
  "home": 59650,
  "stat": 59648,
  "qr": 59649
};
/* eslint-enable */

const iconSet = createIconSet(glyphMap, 'icomoon', 'icomoon.ttf');

export default iconSet;
export const {Button, getImageSource, getImageSourceSync} = iconSet;
/* eslint-disable-next-line */

