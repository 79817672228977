import {Place} from '@typings/ApiSpec';
import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {getPlaceAppearance} from '@utils/api';
import {Logger} from '@b2cmessenger/doppio-shared';

// Set place appearance if not exist
export async function checkAndUpdatePlaceAppearance(
  placeId: Place['id'],
  force?: boolean,
) {
  try {
    const placeAppearance = PlaceAppearance.storage.getAppearance(placeId);
    if (!placeAppearance || force) {
      const fetchedPlaceAppearance = await getPlaceAppearance(placeId);
      if (fetchedPlaceAppearance) {
        await PlaceAppearance.storage.setAppearance(
          placeId,
          fetchedPlaceAppearance,
        );
      }
    }
  } catch (e) {
    Logger.errorTag('checkAndUpdatePlaceAppearance', e);
  }
}
