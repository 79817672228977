import React, {ComponentProps, PropsWithChildren, useMemo} from 'react';
import {
  FlatList,
  StyleSheet,
  Text,
  TextProps,
  TouchableOpacity,
  TouchableOpacityProps,
  View,
} from 'react-native';
import {colors} from '@b2cmessenger/doppio-shared';
import {Screen, Typography} from '@b2cmessenger/doppio-components';

export function IconButton({
  label,
  caption,
  children,
  IconComponent,
  style: _style,
  captionStyle: _captionStyle,
  labelStyle: _labelStyle,
  ...rest
}: PropsWithChildren<
  TouchableOpacityProps & {
    label: string;
    caption?: string;
    fill?: string;
    labelStyle?: TextProps['style'];
    captionStyle?: TextProps['style'];
    IconComponent: React.ComponentType<{size: number; fill?: string}>;
  }
>) {
  const ibStyles = useMemo(
    () =>
      StyleSheet.create({
        wrapper: {
          flexDirection: 'row',
          alignItems: 'center',
          height: 60,
          paddingVertical: 10,
        },
        content: {
          alignSelf: 'stretch',
          borderBottomColor: colors.lightgray,
          borderBottomWidth: 1,
          marginBottom: -10,
          paddingBottom: 10,
          marginLeft: 8,
          flexGrow: 1,
        },
        contentRow: {
          marginTop: 'auto',
          marginBottom: 'auto',
          flexDirection: 'row',
          alignItems: 'center',
        },
        textRow: {
          flexGrow: 1,
        },
      }),
    [],
  );
  const style = useMemo(() => [ibStyles.wrapper, _style], [
    _style,
    ibStyles.wrapper,
  ]);
  const captionStyle = useMemo(() => [Typography.smallBody, _captionStyle], [
    _captionStyle,
  ]);
  const labelStyle = useMemo(() => [Typography.mediumBody, _labelStyle], [
    _labelStyle,
  ]);

  return (
    <TouchableOpacity {...rest} style={style}>
      <IconComponent size={28} fill={rest.fill} />
      <View style={ibStyles.content}>
        <View style={ibStyles.contentRow}>
          <View style={ibStyles.textRow}>
            {/* @ts-ignore */}
            <Text style={labelStyle}>{label}</Text>
            {caption !== undefined ? (
              /* @ts-ignore */
              <Text style={captionStyle}>{caption}</Text>
            ) : null}
          </View>
          {children}
        </View>
      </View>
    </TouchableOpacity>
  );
}

export function IconButtonList({
  data,
}: {
  data: Array<ComponentProps<typeof IconButton>>;
}) {
  return (
    <FlatList
      data={data}
      renderItem={({item: props}) => <IconButton {...props} />}
      keyExtractor={(item) => item.label}
      contentContainerStyle={styles.listContainer}
    />
  );
}

const styles = StyleSheet.create({
  listContainer: {paddingHorizontal: Screen.PADDING_HORIZONTAL},
});
