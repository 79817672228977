import {useLayoutEffect} from 'react';
import {Platform} from 'react-native';
import {useIsFocused} from '@react-navigation/native';
export function useSetThemeColorMetaTag(color: string) {
  const isFocused = useIsFocused();

  useLayoutEffect(() => {
    if (Platform.OS === 'web' && isFocused) {
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute('content', color);
    }
  }, [color, isFocused]);
}
