import {combineReducers} from '@reduxjs/toolkit';

import {slices} from '@b2cmessenger/doppio-core';
import {reducer as loginJwtReducer} from './loginJwt.slice';

import {reducer as stampsReducer} from './slices/stamps';
import {reducer as userPlacesReducer} from './slices/userPlaces';
import {reducer as appTrackingReducer} from './slices/appTracking';

import {persistReducer} from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';

const initReducer = persistReducer(
  {
    key: 'init',
    version: 1,
    storage: AsyncStorage,
    blacklist: ['status'],
  },
  slices.init.reducer,
);

const reducer = combineReducers({
  init: initReducer,
  loginedUser: slices.loginedUser.reducer,
  app: slices.app.reducer,
  loginJwt: loginJwtReducer,
  stamps: stampsReducer,
  stampAwards: slices.stampAwards.reducer,
  userPlaces: userPlacesReducer,
  appTracking: appTrackingReducer,
  network: slices.network.reducer,
});

export type RootState = ReturnType<typeof reducer>;

const parentSelectors = {
  init: (state: RootState) => state.init,
  loginedUser: (state: RootState) => state.loginedUser,
  app: (state: RootState) => state.app,
  loginJwt: (state: RootState) => state.loginJwt,
  stamps: (state: RootState) => state.stamps,
  stampAwards: (state: RootState) => state.stampAwards,
  userPlaces: (state: RootState) => state.userPlaces,
  appTracking: (state: RootState) => state.appTracking,
  network: (state: RootState) => state.network,
};

export {reducer, parentSelectors};
