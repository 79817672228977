import React, {useCallback} from 'react';
import * as JWT from '@utils/JWT';
import {execute} from '@utils/StampOperation';

export function useStampOperation() {
  const [loading, setLoading] = React.useState(false);

  const run = useCallback(async (parsedJwt: ReturnType<typeof JWT.parse>) => {
    if (parsedJwt.type === 'sa' || parsedJwt.type === 'sg') {
      const {placeId, issuedBy, quantity} = parsedJwt;
      setLoading(true);
      const result = await execute(parsedJwt.type === 'sa' ? 'award' : 'give', {
        placeId,
        issuedBy,
        jwt: parsedJwt.jwt,
      });
      setLoading(false);

      if ('error' in result) {
        return typeof result.error === 'object' && parsedJwt.type === 'sa'
          ? {
              placeId,
              issuedBy,
              quantity,
              awardQuantity: parsedJwt.quantity,
              awardId: parsedJwt.awardId,
              error: result.error,
            }
          : {
              placeId,
              issuedBy,
              quantity,
              error: result.error,
              code: result.code,
            };
      }

      return result;
    }

    return null;
  }, []);

  return {
    loading,
    run,
  };
}
