import {useEffect, useState} from 'react';
import {localization} from '@shared';
import {LANGUAGES} from './index';

function useGetCurrentLanguageName() {
  const [currentLanguageName, setCurrentLanguageName] = useState(
    getCurrentLanguageName(localization.getLanguage()),
  );

  useEffect(() => {
    const updateCurrentLanguage = (lng: string) => {
      setCurrentLanguageName(getCurrentLanguageName(lng));
    };
    localization.events.addListener('languageChanged', updateCurrentLanguage);
    return () => {
      localization.events.removeListener(
        'languageChanged',
        updateCurrentLanguage,
      );
    };
  }, []);

  return {currentLanguageName};
}

function getCurrentLanguageName(lng: string) {
  return LANGUAGES.find(l => lng.match(l.lng))?.name || LANGUAGES[0].name;
}

export default useGetCurrentLanguageName;
