import {takeLatest} from 'redux-saga/effects';
import {changeLanguage} from '@store/actions';
import {Logger} from '@b2cmessenger/doppio-shared';
import {localization} from '@shared';

export default function* localizationSaga() {
  yield takeLatest(
    changeLanguage,
    function* (action: ReturnType<typeof changeLanguage>) {
      Logger.verboseTag('localizationSaga', 'changeLanguage');

      const {payload} = action;
      if (hasLocale(payload)) {
        try {
          const {locale} = payload;
          yield localization.changeLanguage(locale);
        } catch (e) {
          Logger.errorTag('localizationSaga', e);
        }
      }
    },
  );
}

function hasLocale(payload: {locale?: string}): payload is {locale: string} {
  return 'locale' in payload && typeof payload.locale === 'string';
}
