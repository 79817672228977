import {
  EventConsumer,
  NavigationContainerEventMap,
  NavigationHelpers,
  NavigationState,
  PartialState,
  Route,
} from '@react-navigation/native';
import {Logger} from '@b2cmessenger/doppio-shared';
import React from 'react';
import {RootNavigatorScreenParams} from './config';

type NavigationContainerRef = NavigationHelpers<RootNavigatorScreenParams> &
  EventConsumer<NavigationContainerEventMap> & {
    resetRoot(
      state?:
        | PartialState<NavigationState<RootNavigatorScreenParams>>
        | NavigationState<RootNavigatorScreenParams>,
    ): void;
    getRootState(): NavigationState<RootNavigatorScreenParams>;
    getCurrentRoute():
      | Route<keyof RootNavigatorScreenParams, RootNavigatorScreenParams>
      | undefined;
    getCurrentOptions(): object | undefined;
  };

const isReadyRef = React.createRef<boolean>();
const navigationRef = React.createRef<NavigationContainerRef>();

function navigate(...args: Parameters<NavigationContainerRef['navigate']>) {
  if (isReadyRef.current) {
    navigationRef.current?.navigate(...args);
  } else {
    Logger.infoTag(
      'navigationRef',
      'attempt to call navigate function while navigation itself not finished initialization',
      JSON.stringify(args),
    );
  }
}

function reset(...args: Parameters<NavigationContainerRef['reset']>) {
  if (isReadyRef.current) {
    navigationRef.current?.reset(...args);
  } else {
    Logger.infoTag(
      'navigationRef',
      'attempt to call reset function while navigation itself not finished initialization',
      JSON.stringify(args),
    );
  }
}

function setNavigationReady() {
  // @ts-ignore
  // noinspection JSConstantReassignment
  isReadyRef.current = true;
}

export {navigationRef, navigate, reset, setNavigationReady};
