import {DefaultTheme} from '@react-navigation/native';
import {colors} from '@b2cmessenger/doppio-shared';

export const NavigationTheme: typeof DefaultTheme = {
  dark: false,
  colors: {
    primary: colors.brand,
    background: colors.white,
    card: colors.white,
    text: colors.black,
    border: colors.middlegray,
    notification: colors.red,
  },
};
