import React, {useCallback, useContext, useEffect, useState} from 'react';
import {
  Button,
  Pusher,
  Spacer,
  TextInput,
  Typography,
  useTheme,
} from '@b2cmessenger/doppio-components';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {StyleSheet, Text} from 'react-native';
import {EmailSignInContext} from '@screens/EmailSignIn/index';
import {EmailSignInError, isValidEmail} from '@screens/EmailSignIn/shared';
import {useTranslation} from '@shared';

type EmailWithPasswordCaseProps = {
  disabled: boolean;
  onSignInWithOTP: (email: string) => void;
  onNext: (email: string, password: string) => void;
};
export function EmailWithPasswordCase({
  onNext,
  disabled,
  onSignInWithOTP,
}: EmailWithPasswordCaseProps) {
  const {t} = useTranslation();
  const {email, loading, error, resetError} = useContext(EmailSignInContext);

  const {colors} = useTheme();
  const {bottom} = useSafeAreaInsets();
  const [_email, _setEmail] = useState(email);
  const [password, setPassword] = useState('');

  useEffect(() => {
    _setEmail(email);
  }, [email]);

  const onPress = useCallback(() => {
    if (loading || !isValidEmail(_email) || password.length === 0) {
      return;
    }
    onNext?.call(null, _email, password);
  }, [_email, password, onNext, loading]);

  const onChangeEmail = useCallback(
    (e: string) => {
      if (error) {
        resetError?.call(null);
      }
      _setEmail(e);
    },
    [error, resetError],
  );
  const onChangePassword = useCallback(
    (e: string) => {
      if (error) {
        resetError?.call(null);
      }
      setPassword(e);
    },
    [error, resetError],
  );

  return (
    <>
      <TextInput
        autoFocus
        error={!!error}
        label={t('Screens.EmailSignIn.WithPassword.email')}
        disabled={disabled}
        textContentType="emailAddress"
        keyboardType="email-address"
        value={_email}
        onChangeText={onChangeEmail}
      />
      <Spacer />
      <TextInput
        autoFocus
        error={!!error}
        label={t('Screens.EmailSignIn.WithPassword.password')}
        secureTextEntry
        disabled={disabled}
        textContentType="password"
        value={password}
        onChangeText={onChangePassword}
      />
      {error ? (
        <>
          <Spacer />
          <EmailSignInError error={error} />
        </>
      ) : null}
      <Spacer />
      <Text style={[Typography.smallBody]}>
        {t('Screens.EmailSignIn.WithPassword.orUse')}{' '}
        <Text
          onPress={() => {
            if (loading) {
              return;
            }
            setPassword('');
            onSignInWithOTP(_email);
          }}
          style={[
            Typography.smallBody,
            styles.link,
            {color: loading ? colors.darkgray : colors.brand},
          ]}>
          {t('Screens.EmailSignIn.WithPassword.passwordfreeSignIn')}
        </Text>
      </Text>
      <Pusher />
      <Button
        title={t('Screens.EmailSignIn.WithPassword.signIn')}
        loading={loading}
        disabled={
          disabled || loading || !isValidEmail(_email) || password.length === 0
        }
        onPress={onPress}
      />
      <Spacer height={Math.max(bottom, 16)} />
    </>
  );
}

const styles = StyleSheet.create({
  link: {
    textDecorationLine: 'underline',
  },
});
