import React, {useMemo} from 'react';
import SvgIcon, {SvgIconProps} from '@components/common/icons/SvgIcon';
import PunchIcon, {glyphMap} from '@components/common/icons/PunchIcon';
import {IconProps} from 'react-native-vector-icons/Icon';
import {StyleSheet, View} from 'react-native';

const defaultIconSize = 20;

type FontIconNames = keyof typeof glyphMap;
type FontIconProps = Omit<IconProps, 'name'> & {
  name: FontIconNames;
};
function isFontIcon(
  props: SvgIconProps | FontIconProps,
): props is FontIconProps {
  return Object.keys(glyphMap).indexOf(props.name) !== -1;
}
export function Icon(
  props: (SvgIconProps | FontIconProps) & {indicator?: boolean},
) {
  const {indicator = false, ...rest} = props;
  const iconProps = {...rest, size: rest.size || defaultIconSize};
  if (indicator) {
    return <IconIndicator {...iconProps} />;
  } else if (isFontIcon(iconProps)) {
    return <PunchIcon {...iconProps} />;
  } else {
    return <SvgIcon {...iconProps} />;
  }
}

export function IconIndicator(props: SvgIconProps | FontIconProps) {
  const {style, ...restIconProps} = props;

  const indicatorContainerStyles = useMemo(
    () => [
      style,
      {
        width: props.size || defaultIconSize,
        height: props.size || defaultIconSize,
      },
    ],
    [props.size, style],
  );

  return (
    <View style={indicatorContainerStyles}>
      <View style={styles.indicator} />
      <Icon {...restIconProps} />
    </View>
  );
}

const styles = StyleSheet.create({
  indicator: {
    position: 'absolute',
    right: '3.57%',
    top: '3.57%',
    height: '25%',
    width: '25%',
    backgroundColor: '#F7440C',
    borderRadius: 50,
    zIndex: 1,
  },
});
