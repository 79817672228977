import {PlaceAppearance} from '@b2cmessenger/doppio-core';
import {localization, useTranslation} from '@shared';
import {useCallback} from 'react';

export default function usePlaceAppearance(placeId: number) {
  const {t} = useTranslation();
  const {award, ...restAppearance} = PlaceAppearance.usePlaceAppearance(
    placeId,
  );

  const currentLanguageCode = localization.getLanguage();

  const awardAltered = useCallback(
    (quantity: number) => {
      if (currentLanguageCode.startsWith('en')) {
        return award(quantity);
      }
      return award(1);
    },
    [award, currentLanguageCode],
  );

  const stampAltered = useCallback(
    (quantity: number) => {
      return t('common.stamp', {count: quantity});
    },
    [t],
  );

  return {...restAppearance, award: awardAltered, stamp: stampAltered};
}
