import React, {PropsWithoutRef, useMemo} from 'react';
import {
  SectionList,
  SectionListData,
  SectionListProps,
  StyleSheet,
  Text,
  View,
  ViewProps,
} from 'react-native';

import {
  Button,
  Spacer,
  ThemeProvider,
  TouchableCard,
  Typography,
} from '@b2cmessenger/doppio-components';
import {NoAwardedPlacesPlaceholder} from '@screens/Dashboard/components/NoAwardedPlacesPlaceholder';
import {AwardedStampedPlace} from '../shared';
import {PlaceLogo} from '@screens/Dashboard/components/PlaceLogo';
import usePlaceAppearance from '@components/hooks/usePlaceAppearance';
import {useTranslation} from '@shared';

type Props = Omit<SectionListProps<any>, 'sections'> & {
  places: AwardedStampedPlace[];
  onPlacePress?: (place: AwardedStampedPlace) => void;
};

export function PlacesAwardsList({places, onPlacePress, ...rest}: Props) {
  const grid = useMemo(
    () =>
      places.length > 0
        ? ({
            name: 'grid',
            data: [places],
            keyExtractor: () => 'places',
            renderItem: ({item}) => (
              <View style={{flexDirection: 'row'}}>
                {renderMasonryGrid({
                  places: item,
                  onPressCard: onPlacePress,
                })}
              </View>
            ),
          } as SectionListData<any>)
        : null,
    [onPlacePress, places],
  );

  return (
    <SectionList
      ListEmptyComponent={NoAwardedPlacesPlaceholder}
      stickySectionHeadersEnabled={false}
      SectionSeparatorComponent={Spacer}
      sections={useMemo(() => (grid ? [grid] : []), [grid])}
      {...rest}
      contentContainerStyle={useMemo(
        () =>
          places.length === 0
            ? [{flex: 1}, rest.contentContainerStyle]
            : rest.contentContainerStyle,
        [places.length, rest.contentContainerStyle],
      )}
      bounces={places.length === 0 ? false : rest.bounces}
    />
  );
}

export function renderMasonryGrid({
  places,
  numColumns = 2,
  columnGutter = 16,
  onPressCard,
}: {
  places: AwardedStampedPlace[];
  numColumns?: number;
  columnGutter?: number;
  onPressCard?: (place: AwardedStampedPlace) => void;
}) {
  const columnStyle = () => ({
    width: `${100 / numColumns}%`,
  });
  const elementStyle = (columnIdx: number, idx: number) => ({
    marginLeft: columnIdx === 0 ? 0 : columnGutter / 2,
    marginRight: columnIdx === numColumns - 1 ? 0 : columnGutter / 2,
    marginTop: idx > 0 ? columnGutter : 0,
  });
  return Array.from({length: numColumns}).map((_, columnIdx) => (
    <View key={String(columnIdx)} style={columnStyle()}>
      {places
        .filter((__, idx) => idx % numColumns === columnIdx)
        .map(({id, ...place}, elementIdx) => {
          return (
            <TouchableCard
              key={String(id)}
              onPress={onPressCard?.bind(null, {
                id,
                ...place,
              })}
              style={elementStyle(columnIdx, elementIdx)}>
              <PlaceWithLoyaltyInfo id={id} {...place} />
            </TouchableCard>
          );
        })}
    </View>
  ));
}

function PlaceWithLoyaltyInfo({
  logo,
  name,
  awards,
  stamps,
  id,
  ...rest
}: PropsWithoutRef<ViewProps & AwardedStampedPlace>) {
  const {t} = useTranslation();

  const {award: getAwardName, color} = usePlaceAppearance(id);

  return (
    <ThemeProvider colors={{brand: color}}>
      <View {...rest}>
        <PlaceLogo uri={logo} size={28} style={styles.logo} fill={color} />
        <Spacer height={8} />
        <Text style={Typography.fatBody}>{name}</Text>
        <Spacer height={8} />
        <View style={styles.buttonsBlock}>
          <View style={styles.buttonWrapper} pointerEvents="none">
            <Button.ExtraSmall
              style={styles.button}
              titleStyle={styles.buttonTitle}
              innerStyle={styles.buttonInner}
              mode="secondary"
              title={t('Screens.Dashboard.Place.rewards', {
                count: awards,
                rewardName: getAwardName(awards),
              })}
            />
          </View>

          <Spacer height={8} />
          <View style={styles.buttonWrapper} pointerEvents="none">
            <Button.ExtraSmall
              style={styles.button}
              titleStyle={styles.buttonTitle}
              innerStyle={styles.buttonInner}
              title={t('Screens.Dashboard.Place.stamps', {
                count: stamps,
              })}
            />
          </View>
        </View>
      </View>
    </ThemeProvider>
  );
}

const buttonPadding = 10;

const styles = StyleSheet.create({
  logo: {
    width: 28,
    height: 28,
    borderRadius: 6,
  },
  buttonsBlock: {
    marginRight: -buttonPadding,
  },
  buttonWrapper: {
    alignSelf: 'flex-start',
    maxWidth: '100%',
  },
  button: {
    borderRadius: 14,
    paddingHorizontal: buttonPadding,
  },
  buttonTitle: {
    textAlign: 'left',
  },
  buttonInner: {
    maxWidth: '100%',
  },
});
