import React, {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {loginedUserSelectors} from '@store/selectors';
import {Navbar} from '@b2cmessenger/doppio-components';
import {LoginedUser} from '@b2cmessenger/doppio-core';

const LoggedInUserNavbar = ({
  onPressOptions,
  onLogout,
}: {
  onPressOptions?: () => void;
  onLogout?: () => void;
}) => {
  const profile = useSelector(loginedUserSelectors.profile);
  const name = useMemo(() => {
    if (!profile) {
      return '';
    }
    return getProfileName(profile);
  }, [profile]);

  return profile !== null ? (
    <Navbar.LoggedInUser
      name={name}
      avatar={profile.avatarThumb || undefined}
      onLogOut={onLogout}
      onPressOptions={onPressOptions}
    />
  ) : null;
};

function getProfileName(profile: LoginedUser) {
  let name = '';
  if (profile.firstname && profile.lastname) {
    name = `${profile.firstname} ${profile.lastname}`;
  } else if (profile.firstname || profile.lastname) {
    name = profile.firstname || profile.lastname || '';
  } else if (profile.email) {
    name = profile.email;
  } else if (profile.phone) {
    name = profile.phone;
  }
  return name;
}

export default LoggedInUserNavbar;
