import {LinkingOptions, PathConfig} from '@react-navigation/native';
import {RootNavigatorScreenParams} from '@navigation/config';

const OptionalString = {
  parse: (v: any) => (v ? String(v) : undefined),
  stringify: (v: any) => (v ? String(v) : ''),
};

export const linking: LinkingOptions = {
  prefixes: [
    'com.getdoppio.app://',
    'https://localhost:19006',
    'https://192.168.31.168:19006',
  ],
  config: {
    screens: {
      Link: {
        path: 'link/:jwt',
        parse: {
          jwt: String,
        },
      },
      SignIn: {
        path: 'sign-in/:jwt?',
        parse: {
          jwt: OptionalString.parse,
        },
        stringify: {
          jwt: OptionalString.stringify,
        },
      },
      Dashboard: {
        path: 'dashboard/:jwt?',
        parse: {
          jwt: OptionalString.parse,
        },
        stringify: {
          jwt: OptionalString.stringify,
        },
      },
      DashboardGuest: {
        path: 'dashboard-guest/:jwt?',
        parse: {
          jwt: OptionalString.parse,
        },
        stringify: {
          jwt: OptionalString.stringify,
        },
      },
      EmailSignIn: {
        path: 'sign-in-email',
      },
      Settings: {
        path: 'settings',
      },
      AccountRemoval: {
        path: 'account/remove',
      },
    },
  },
};

type RootPathConfigMap = Record<
  keyof RootNavigatorScreenParams,
  string | PathConfig
>;
