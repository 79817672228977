import {batch, useDispatch} from 'react-redux';
import {useCallback} from 'react';
import {Logger} from '@b2cmessenger/doppio-shared';
import {performInit, updateUser} from '@store/actions';
import {UserOwn} from '@typings/ApiSpec';
import {api} from '@b2cmessenger/doppio-core';
import {AxiosError, AxiosRequestConfig} from 'axios';

export default function useAuthorizeByAccessToken() {
  const dispatch = useDispatch();

  const authorizeByAccessToken = useCallback(
    async (accessToken: string, requestConfig?: AxiosRequestConfig) => {
      const userProfile = await loadUserProfileByAccessToken(
        accessToken,
        requestConfig,
      );

      if (!userProfile) {
        Logger.errorTag('LinkAuth', 'Error while loading user profile');
        return;
      }

      const {access_token: _accessToken, ...loginedUser} = userProfile;

      batch(() => {
        dispatch(updateUser({accessToken, loginedUser}));
        dispatch(performInit());
      });

      return loginedUser;
    },
    [dispatch],
  );

  return {
    authorizeByAccessToken,
  };
}

function loadUserProfileByAccessToken(
  accessToken: string,
  requestConfig?: AxiosRequestConfig,
): Promise<UserOwn | undefined> {
  return api.default
    .get<UserOwn>('/api/v2/user/profile', {
      ...requestConfig,
      headers: {
        ...requestConfig?.headers,
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then(api.getResponseData)
    .catch((e: AxiosError) => {
      if (e.response && e.response.status === 401) {
        Logger.infoTag('LinkAuth', 'Invalid accessToken');
      }
      return undefined;
    });
}
