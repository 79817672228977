import React, {useMemo} from 'react';
import {View, ViewProps} from 'react-native';
import {IconStampType} from '@typings/shared';

export function StampListRow({
  stampPerRow = 5,
  stampSize = 48,
  value,
  style,
  IconStamp,
}: {
  stampPerRow?: number;
  stampSize?: number;
  value: number;
  style?: ViewProps['style'];
  IconStamp: IconStampType;
}) {
  return useMemo(
    () => (
      <View style={[{flexDirection: 'row'}, style]}>
        {Array.from({length: stampPerRow}).map((_, idx) => (
          <IconStamp
            key={String(idx + 1)}
            size={stampSize}
            active={value >= idx + 1}
          />
        ))}
      </View>
    ),
    [stampPerRow, stampSize, style, value],
  );
}
