import {
  TouchableWithoutFeedback,
  TouchableWithoutFeedbackProps,
  View,
  ViewProps,
  ViewStyle,
} from 'react-native';
import {useTheme} from '@b2cmessenger/doppio-components';
import {CheckboxIcon} from '@components/common/icons/SvgIcon';
import React, {PropsWithChildren, useMemo} from 'react';

type CheckboxProps = {
  status: 'checked' | 'unchecked';
} & TouchableWithoutFeedbackProps;
function Checkbox({status, onPress, disabled, ...props}: CheckboxProps) {
  const {colors} = useTheme();
  const {middlegray, brand} = colors;

  return (
    <TouchableWithoutFeedback
      accessibilityRole="checkbox"
      onPress={disabled ? undefined : onPress}
      {...props}>
      <CheckboxIcon
        active={status === 'checked'}
        color={disabled ? middlegray : brand}
        size={28}
      />
    </TouchableWithoutFeedback>
  );
}

function CheckboxItem({
  children,
  style: _style,
  contentContainerStyle: _contentContainerStyle,
  ...rest
}: PropsWithChildren<CheckboxProps> & {
  contentContainerStyle?: ViewProps['style'];
}) {
  const style = useMemo(
    () => [
      {
        marginLeft: 'auto',
      },
      _style,
    ],
    [_style],
  );
  const contentContainerStyle = useMemo(
    () =>
      [
        {
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          minHeight: 60,
        },
        _contentContainerStyle,
      ] as ViewStyle,
    [_contentContainerStyle],
  );
  return (
    <View style={contentContainerStyle}>
      <View
        style={{
          flexShrink: 1,
          marginRight: 16,
        }}>
        {children}
      </View>
      <View style={style}>
        <Checkbox {...rest} />
      </View>
    </View>
  );
}

export default CheckboxItem;
