import React, {ComponentProps} from 'react';
import {
  Button,
  Spacer,
  TouchableCard,
  Typography,
} from '@b2cmessenger/doppio-components';
import {IconAppClient} from '@components/common/icons/SvgIcon';
import {StyleSheet, Text, View} from 'react-native';
import {useTranslation} from '@shared';

type SignInBannerProps = {
  onPress: () => void;
  style?: ComponentProps<typeof TouchableCard>['style'];
};
function SignInBanner({onPress, style}: SignInBannerProps) {
  const {t} = useTranslation();
  const title = t('Screens.SignIn.Banner.signIn');
  const description = t('Screens.SignIn.Banner.toSecureYourStampsAndRewards');
  const buttonText = t('Screens.SignIn.Banner.signIn');
  return (
    <TouchableCard
      onPress={onPress}
      style={[styles.wrapper, style ? style : {}]}>
      <IconAppClient size={105} />
      <View style={styles.info}>
        <Text style={Typography.smallHeader}>{title}</Text>
        <Spacer height={4} />
        <Text style={Typography.body}>{description}</Text>
        <Spacer height={8} />
        <Button.ExtraSmall title={buttonText} onPress={onPress} />
      </View>
    </TouchableCard>
  );
}

export default SignInBanner;

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    marginLeft: 16,
  },
});
